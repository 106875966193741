import { useState } from 'react';

const useGrabarCliente = () => {
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const grabarCliente = async (clienteData) => {
    setLoading(true);
    setError(null);
    setResponse(null);

    try {
      const response = await fetch('https://app.j4pro.com/jServerj4ErpPro/api/token/grabarCliente', {
        method: 'POST',
        headers: {
          'X-Auth-Token-empresa': '4411',
          'X-Auth-Token-api': '4411-202002620008ed7rv1194-7bfe-rc8e-b977-a17e04665f26e', 
          'Content-Type': 'application/json',
          'X-gtm': 'GMT-0500'
        },
        body: JSON.stringify({
          ...clienteData,
          primer_nombre: '',
          segundo_nombre: '',
          direccion: 'Medellín', 
          es_activo: '1',
          es_cliente: 1,
          es_proveedor: 0,
          puntos_acumulados: 0,
          permite_cartera_vencida: '1',
          codigo_interno: '',
          numero_matricula: null,
          permite_saldo_cartera: '0',
          cupo_cartera: 0,
          permite_cartera: '1',
          id_tipo_retencion_ventas: 1,
          id_tipo_retencion_compra: 1,
          envioSmsCartera: '0',
          envioSmsProducto: '0',
          ciudad: 'MEDELLIN',
          zona: '',
          contacto: '',
          departamento: 'ANTIOQUIA',
          pais: 'Colombia',
          id_tipo_identificacion: '3'
        })
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      setResponse(data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return { response, loading, error, grabarCliente };
};

export default useGrabarCliente;
