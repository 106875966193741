import React, { useState } from 'react';
import { Button, Card, Divider, Form, Input, Select } from 'antd';
import ImageLoader from '../../layout/components/patients/ImageLoader';
import { useGetUser } from '../../hooks/useGetUser';
import { useSucursales } from '../../hooks/useSucursales';
import { useUpdateUser } from '../../hooks/useUpdateUser';
import { useAlert } from '../../context/AlertContext';
import { usePageData } from '../../hooks/usePage';

const FormItem = Form.Item;
const Option = Select.Option;

const UserAvatar = ({ src, className = null }) => {
  return (
    <div className={`avatar-wrapper ${className}`}> 
      <ImageLoader src={src} size={100} />
    </div>
  );
};

const AccountForm = ({ user }) => {
  const { sucursales } = useSucursales();
  const { updateUser } = useUpdateUser();
  const alertContext = useAlert();

  const [formData, setFormData] = useState({
    id: user.id,
    responsable: user.responsable || '',
    empleado: user.empleado || '',
    tipoUsuario: user.rol || '',
    sucursal: user.sucursal || '',
    correo: user.correo || '',
    celular: user.celular || '',
    estado: user.estado || '',
    clave: user.clave || ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSelectChange = (value, name) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const validateForm = () => {
    if (!formData.responsable || !formData.correo) {
      return false;
    }
    return true;
  };

  const handleSubmit = async (values) => {
    if (!validateForm()) {
      alertContext.triggerAlert({
        type: 'error',
        message: 'Datos Incompletos',
        description: 'Debe llenar los campos obligatorios'
      });
      return;
    }

    const result = await updateUser(formData);
    if (result) {
      alertContext?.triggerAlert({
        type: 'success',
        message: 'Proceso realizado',
        description: 'Usuario: ' + formData.responsable + ' Actualizado Correctamente'
      });
    } else {
      alertContext?.triggerAlert({
        type: 'error',
        message: 'Error Actualizando el Usuario',
        description: 'No se pudo Actualizar el Usuario'
      });
    }
  };

  return (
    <>
    <Card style={{ background: 'rgba(251, 251, 251)' }}
            className='animated with-shadow'>
    <Form layout='vertical' onFinish={handleSubmit}>
      {/* Campos del formulario */}
      <FormItem label='Usuario' required>
        <Input
          name='responsable'
          onChange={handleChange}
          value={formData.responsable}
          placeholder='Usuario'
        />
      </FormItem>

      <FormItem label='Empleado Id_ERP'>
        <Input
          name='empleado'
          onChange={handleChange}
          value={formData.empleado}
          placeholder='Empleado Id_ERP'
          disabled={true}
        />
      </FormItem>

      <FormItem label='Tipo de usuario'>
        <Input name='tipoUsuario' value={formData.tipoUsuario} placeholder='' disabled={true} />
      </FormItem>

      <FormItem label='Sucursal'>
        <Select
          onChange={(value) => handleSelectChange(value, 'sucursal')}
          value={formData.sucursal}
          placeholder='Seleccione una sucursal'
          className='mb-lg-0 mb-4'
          showSearch
          optionFilterProp='children'
        >
          {sucursales.map((sucursal) => (
            <Option key={sucursal.id} value={sucursal.id}>
              {sucursal.descripcion}
            </Option>
          ))}
        </Select>
      </FormItem>

      <FormItem label='Correo' required>
        <Input name='correo' onChange={handleChange} value={formData.correo} placeholder='Correo' />
      </FormItem>

      <FormItem label='Número Celular'>
        <Input
          name='celular'
          onChange={handleChange}
          value={formData.celular}
          placeholder='Celular'
        />
      </FormItem>

      <FormItem label='Estado' required>
        <Input name='estado' onChange={handleChange} value={formData.estado} placeholder='Estado' />
      </FormItem>

      <FormItem label='Clave' required>
        <Input
          type='password'
          name='clave'
          onChange={handleChange}
          value={formData.clave}
          placeholder='Clave'
        />
      </FormItem>

      <div className='elem-list justify-content-between'>
        <Button type='primary' htmlType='submit'>
          Guardar Cambios
        </Button>
      </div>
    </Form>
    </Card>
    </>
  );
};

const EditAccountPage = () => {
  const { user, loading, error } = useGetUser();
  usePageData({ title: 'Usuario', fulFilled: true });

  if (loading) return <p>Cargando...</p>;
  if (error) return <p>Error: {error}</p>;
  if (!user) return <p>No se encontró el usuario.</p>;

  return (
    <div className='stack' style={{ maxWidth: 690, margin: '0 auto' }}>
      <UserAvatar className='mt-0' src={user.img || '/content/perfil1.jpg'} />
      <AccountForm user={user} />
      <Divider />
    </div>
  );
};

export default EditAccountPage;
