// pages/ProcesoIngreso.tsx
import "./customSteps.scss";

import {
  CarOutlined,
  DatabaseOutlined,
  DoubleLeftOutlined,
  ToolOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Button, Card, Form, FormInstance, message, Steps } from "antd";
import React, { useCallback, useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { usePageData } from "../../../hooks/usePage";
import BdIngresos from "./BdIngresos";
import IngresoClientePage from "./IngresoCliente";
import ProcesoVehiculoPage from "./ProcesoVehiculo";
import ServicioIngresoPage from "./ServicioIngreso";
import { handleSaveImage } from "./GuardarImagenVehiculo";

const { Step } = Steps;

const ProcesoIngreso: React.FC = () => {
  const navigate = useNavigate();
  const { placa: placaParams } = useParams<{ placa: string }>();
  const [placa, setPlaca] = useState('');
  const [currentStep, setCurrentStep] = useState(0);
  const [idCategoria, setIdCategoria] = useState<string | null>(null);
  const [clienteData, setClienteData] = useState<any>({});
  const [vehiculoData, setVehiculoData] = useState<any>({});
  const [vehiculoObservacion, setVehiculoObservacion] = useState('');

  // Ref para la stage del vehículo
  const stageRef = useRef(null);

  // Set page data loading fulfilled
  usePageData({ title: 'Proceso de ingreso', fulFilled: true });

  // Declare forms
  const [clienteForm] = Form.useForm();
  const [vehiculoForm] = Form.useForm();
  const [servicioForm] = Form.useForm();

  // Step Functions
  const validateStep = async ({
    currentStep,
    clienteForm,
    vehiculoForm,
    servicioForm,
    setVehiculoData,
    setClienteData,
    setVehiculoObservacion,
    categoriaChange
  }) => {
    try {
      switch (currentStep) {
        case 0:
          const values = await clienteForm.validateFields();
          categoriaChange(values.tipo_vehiculo);
          setPlaca(values.placa);
          setVehiculoData((prevVehiculoData) => ({
            ...prevVehiculoData,
            ...values
          }));
          setClienteData(values);
          break;
        case 1:
          const valuesVehiculo = await vehiculoForm.validateFields();
          setVehiculoData((prevVehiculoData) => ({
            ...prevVehiculoData,
            ...valuesVehiculo
          }));
          setVehiculoObservacion(valuesVehiculo.observaciones);
          break;
        case 2:
          await servicioForm.validateFields();
          if (!vehiculoData.observacion)
            setVehiculoData((prevVehiculoData) => ({ ...prevVehiculoData, observacion: '' }));
          break;
      }
      return true;
    } catch (error) {
      message.warning('Debe llenar los datos para continuar.');
      return false;
    }
  };

  const handleStepChange = useCallback(
    async (nextStep: number) => {
      if (nextStep > currentStep) {
        const isValid = await validateStep({
          currentStep,
          clienteForm,
          vehiculoForm,
          servicioForm,
          setVehiculoData,
          setClienteData,
          setVehiculoObservacion,
          categoriaChange: setIdCategoria
        });
        if (!isValid) return;
      }

      // Llamar a handleSave cada vez que se cambia de paso
      if (currentStep === 1) {
        handleSaveImage(stageRef, placa);
      }

      setCurrentStep(nextStep);
    },
    [clienteForm, currentStep, servicioForm, vehiculoForm]
  );

  const steps = [
    {
      title: 'Cliente',
      content: <IngresoClientePage form={clienteForm} placa={placa} />,
      icon: <UserOutlined />
    },
    {
      title: 'Vehículo',
      content: <ProcesoVehiculoPage placa={placa} form={vehiculoForm} setVehiculoObservacion={setVehiculoObservacion} stageRef={stageRef} />,
      icon: <CarOutlined />
    },
    {
      title: 'Servicio',
      content: (
        <ServicioIngresoPage
          id_categoria={idCategoria}
          clienteData={clienteData}
          vehiculoData={{ ...vehiculoData, observacion: vehiculoObservacion }}
        />
      ),
      icon: <ToolOutlined />
    },
    {
      title: 'Resumen',
      content: <BdIngresos placa={placa} />, 
      icon: <DatabaseOutlined />
    }
  ];

  useEffect(() => {
    if (placaParams) {
      setPlaca(placaParams);
    }
  }, [placaParams]);

  return (
    <>
      <Card style={{ background: 'rgba(251, 251, 251)' }} className='animated with-shadow'>
        <div className='proceso-ingreso'>
          <Button
            type='primary'
            ghost
            icon={<DoubleLeftOutlined />}
            onClick={() => navigate(-1)}
            style={{ marginBottom: '10px' }}
          >
            Regresar
          </Button>

          <Steps current={currentStep} onChange={handleStepChange} direction='horizontal'>
            {steps.map((step, index) => (
              <Step key={index} title={step.title} icon={step.icon} />
            ))}
          </Steps>

          <div className='steps-content'>
            {steps.map((step, index) => (
              <div key={index} style={{ marginTop: '30px', display: index === currentStep ? 'block' : 'none' }}>
                {step.content}
              </div>
            ))}
          </div>
        </div>
      </Card>
    </>
  );
};

export default ProcesoIngreso;