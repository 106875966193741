import React from 'react';
import './TextEmpresa.scss';

type Props = {
  layout: 'vertical' | 'horizontal';
};

const Search = ({ layout = 'vertical' }: Props) => {
  return (
    <div className={`app-search app-search--${layout}`}>
      APRISA LAVADO
    </div>
  );
};

export default Search;
