import { IRoute } from '../interfaces/routing';

import SettingsPage from '../pages/settings/Settings';
import UsuariosPage from '../pages/usuario/Usuarios';
import InvoicesPage from '../pages/services/InvoicesPage';
import InvoicePage from '../pages/services/InvoicePage';
import PricingPage from '../pages/services/PricingPage';
import EventsTimelinePage from '../pages/services/events-timeline/EventsTimeline';
import UserProfilePage from '../pages/services/UserProfilePage';
import EditAccountPage from '../pages/services/EditAccounPage';
import EventsCalendarPage from '../pages/services/EventsCalendarPage';
import IngresosPage from '../pages/ingresos/IngresoPage';
import ClientesPage from '../pages/contactos/clientes/clientes';
import PerfilClientePage from '../pages/contactos/clientes/perfilCliente';
import ProductosPage from '../pages/productos/productos';
import PerfilProductoPage from '../pages/productos/perfilProducto';
import ProcesoIngreso from '../pages/ingresos/proceso/ProcesoIngreso';
import VehiculosPage from '../pages/vehiculos/vehiculos';
import PerfilVehiculoPage from '../pages/vehiculos/PerfilVehiculoPage';
import InformePage from '../pages/informes/InformePage';

export const defaultRoutes: IRoute[] = [
  {
    path: 'settings',
    component: SettingsPage
  },
  {
    path: 'usuarios',
    component: UsuariosPage
  },
  {
    path: 'ingresos',
    component: IngresosPage
  },
  {
    path: 'invoices',
    component: InvoicesPage
  },
  {
    path: 'invoice',
    component: InvoicePage
  },
  {
    path: 'pricing',
    component: PricingPage
  },
  {
    path: 'events-timeline',
    component: EventsTimelinePage
  },
  {
    path: 'user-profile',
    component: UserProfilePage
  },
  {
    path: 'edit-account',
    component: EditAccountPage
  },
  {
    path: 'events-calendar',
    component: EventsCalendarPage
  },
  {
    path: 'clientes',
    component: ClientesPage
  },
  {
    path: 'perfil-clientes',
    component: PerfilClientePage
  },
  {
    path: 'perfil-clientes/:id',
    component: PerfilClientePage
  },
  {
    path: 'articulos',
    component: ProductosPage
  },
  {
    path: 'crear-articulo',
    component: PerfilProductoPage
  },
  {
    path: 'editar-articulo/:id',
    component: PerfilProductoPage
  },
  {
    path: 'crear-vehiculoCliente',
    component: PerfilVehiculoPage
  },
  {
    path: 'editar-vehiculoCliente/:placa',
    component: PerfilVehiculoPage
  },
  {
    path: 'proceso-ingreso',
    component: ProcesoIngreso
  },
  {
    path: 'proceso-ingreso/:placa',
    component: ProcesoIngreso
  },
  {
    path: 'vehiculos',
    component: VehiculosPage
  },
  {
    path: 'informes',
    component: InformePage
  }
];
