import { useState } from 'react';
import { API_BASE_URL } from '../config/apiConfig';

export function useInsertCliente() {
    const [isInserting, setIsInserting] = useState(false);
    const [result, setResult] = useState(null);
    const [error, setError] = useState(null);

    const insertCliente = async (clienteData) => {
        setIsInserting(true);
        setError(null); 
        try {
            const response = await fetch(`${API_BASE_URL}/clientes`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    action: 'insertar',
                    ...clienteData
                })
            });

            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.error || `HTTP error! Status: ${response.status}`);
            }

            setResult(data);
            if (data.success) {
                return { success: true, id: data.id }; 
            } else {
                throw new Error(data.error || 'No se pudo insertar el cliente correctamente.');
            }
        } catch (e) {
            setError((e as Error).message);
            return { success: false, error: (e as Error).message };
        } finally {
            setIsInserting(false);
        }
    };

    return { insertCliente, result, isInserting, error };
}