import React, { createContext, useContext, useState } from 'react';
import { Alert, Card } from 'antd';
import { CloseCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';

const AlertContext = createContext(null);

export const useAlert = () => useContext(AlertContext);

export const AlertProvider = ({ children }) => {
  const [alert, setAlert] = useState(null);

  const triggerAlert = ({ message, type, description }) => {
    const icon = type === 'success' ? <CheckCircleOutlined  /> : <CloseCircleOutlined />;
    setAlert({
      message,
      description,
      type,
      icon
    });
    setTimeout(() => {
      setAlert(null); 
    }, 3500);
  };

  return (
    <AlertContext.Provider value={{ triggerAlert }}>
      {children}
      {alert && (
        <Card title={alert.type === 'success' ? '' : ''} className='alert-card' style={{
          position: 'fixed', 
          top: '20px', 
          right: '20px', 
          zIndex: 1000, 
          width: '400px' 
        }}>
          <Alert
            message={alert.message}
            description={alert.description}
            type={alert.type}
            showIcon
            icon={alert.icon}
            closable
            onClose={() => setAlert(null)}
          />
        </Card>
      )}
    </AlertContext.Provider>
  );
};