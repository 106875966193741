import { useState } from 'react';
import { API_BASE_URL } from '../config/apiConfig';

export function useActualizarCliente() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [isUpdated, setIsUpdated] = useState(false);

    const updateCliente = async (userData) => {
        if (!userData.id) {
            setError("El id es requerido para actualizar.");
            console.error("No ID provided for updating.");
            return null;
        }

        setLoading(true);
        setIsUpdated(false);
        try {
            const response = await fetch(`${API_BASE_URL}/clientes`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ...userData,
                    action: "actualizarCliente"  
                })
            });

            if (!response.ok) {
                const errorData = await response.json();  
                throw new Error(errorData.error || `HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
            console.log("Response Data:", data); 
            
            if (data.message && data.message.includes("Cliente actualizado con éxito")) {
                setIsUpdated(true);
                return data;
            } else {
                throw new Error(data.error || 'Error actualizando el cliente');
            }
        } catch (error) {
            setError(error instanceof Error ? error.message : 'ha ocurrido un error');
            console.error("Update Error:", error);
            return null;
        } finally {
            setLoading(false);
        }
    };

    return { updateCliente, loading, error, isUpdated };
}
