import { useState, useEffect } from 'react';
import { IUsuario } from '../interfaces/patient';
import { API_BASE_URL } from '../config/apiConfig';
import { useAuth } from '../context/AuthContext';

export function useGetUser() {
  const [user, setUser] = useState<IUsuario | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const auth = useAuth();

  useEffect(() => {
    if (auth.user && auth.user.id) {
      setLoading(true);
      const fetchData = async () => {
        try {
          
          const body = JSON.stringify({
            action: 'obtener',
            id: auth.user.id
          });

          const response = await fetch(`${API_BASE_URL}/usuario`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: body
          });

          if (!response.ok) {
            throw new Error('Network response was not ok');
          }

          const data = await response.json();
          if (data.usuario) {
            setUser(data.usuario);
          } else {
            throw new Error('Usuario no encontrado');
          }
        } catch (error) {
          setError(error instanceof Error ? error.message : 'Ocurrió un error');
        } finally {
          setLoading(false);
        }
      };

      fetchData();
    } else {
      setUser(null);
      setLoading(false);
    }
  }, [auth.user]);  

  return { user, loading, error };
}