import { useState, useCallback, useEffect } from 'react';
import { API_BASE_URL } from '../config/apiConfig';

export function useLocationData() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [paises, setPaises] = useState([]);
    const [departamentos, setDepartamentos] = useState([]);
    const [municipios, setMunicipios] = useState([]);

    const loadPaises = useCallback(async () => {
        setLoading(true);
        try {
            const response = await fetch(`${API_BASE_URL}/catubicacion`, { method: 'POST' });
            if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
            const data = await response.json();
            setPaises(data.paises); 
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    }, []);

    const loadDepartamentos = useCallback(async (idPais) => {
        try {
            const response = await fetch(`${API_BASE_URL}/catubicacion?id_pais=${idPais}`, { method: 'POST' });
            if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
            const data = await response.json();
            setDepartamentos(data.departamentos); 
        } catch (error) {
            setError(error);
        }
    }, []);

    const loadMunicipios = useCallback(async (idDepartamento) => {
        try {
            const response = await fetch(`${API_BASE_URL}/catubicacion?id_departamento=${idDepartamento}`, { method: 'POST' });
            if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
            const data = await response.json();
            setMunicipios(data.municipios); 
        } catch (error) {
            setError(error);
        }
    }, []);

    useEffect(() => {
        loadPaises();
    }, [loadPaises]);

    return { loadDepartamentos, loadMunicipios, paises, departamentos, municipios, loading, error };
}