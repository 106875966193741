import React, { useState } from 'react';
import { usePageData } from '../../hooks/usePage';
import { useAllUser } from '../../hooks/useAllUser';
import UsersTable from './UserTable';
import { Navigate } from 'react-router-dom';
import { useUser } from '../../hooks/useUser';
import PageAction from '../../layout/components/page-action/PageAction';
import Modal from 'antd/lib/modal/Modal';
import UsuarioForm from './UsuarioForm';
import { IUsuarioAll3 } from '../../interfaces/usuarios';
import { useSucursales } from '../../hooks/useSucursales';
import { Spin } from 'antd';


const UsuariosPage: React.FC = () => {
  const { loading, usuariosAll, addUser, updateUser } = useAllUser();
  const [visible, setVisibility] = useState(false);
  const { sucursales } = useSucursales();

  const [selectedUser, setSelectedUser] = useState<IUsuarioAll3 | null>(null);
  const [userId, setUserId] = useState<string | null>(null);

  usePageData({ title: 'Usuarios', fulFilled: true });
  const { isAuthenticated, role } = useUser();
  const allowedRoles = ['admin'];

  if (!isAuthenticated || !allowedRoles.includes(role)) {
    return <Navigate to='/public/page-404' replace />;
  }

  if (loading ) {
    return (
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh' 
      }}>
        <Spin size='large' tip='Cargando...' />
      </div>
    );
  }

  const handleCreateUser = () => {
    setSelectedUser(null);
    setUserId(null);
    setVisibility(true);
  };

  const handleEditUser = (user: IUsuarioAll3) => {
    setSelectedUser(user);
    setUserId(user.id.toString());
    setVisibility(true);
  };

  const handleSubmit = (userData: IUsuarioAll3) => {
    const sucursalData = sucursales.find((s) => s.id === userData.sucursal);
    const updatedUserData = {
      ...userData,
      sucursal: sucursalData ? sucursalData.descripcion : userData.sucursal
    };

    if (selectedUser) {
      updateUser(updatedUserData);
    } else {
      addUser(updatedUserData);
    }
    setVisibility(false);
  };

  const TitleComponent = () => (
    <h6 className='m-0' style={{ width: '100%', margin: '0', display: 'block' }}>
      {selectedUser ? (
        <span
          style={{
            display: 'block',
            width: '100%',
            background: '#fff1f0',
            padding: '10px',
            borderRadius: '4px',
            textAlign: 'center'
          }}
        >
          Editar Usuario N° {userId}
        </span>
      ) : (
        <span
          style={{
            display: 'block',
            width: '100%',
            background: '#f6ffed',
            padding: '10px',
            borderRadius: '4px',
            textAlign: 'center'
          }}
        >
          Crear Usuario
        </span>
      )}
    </h6>
  );

  return (
    <>
      <UsersTable usuariosAll={usuariosAll} onEditUser={handleEditUser} />
      <PageAction onClick={handleCreateUser} icon='icofont-plus' type='primary' />
      <Modal
        title={<TitleComponent />}
        open={visible}
        onCancel={() => setVisibility(false)}
        footer={null}
        destroyOnClose
        maskClosable={false}
       
      >
        <UsuarioForm
          onSubmit={handleSubmit}
          onClose={() => setVisibility(false)}
          initialData={selectedUser}
          userId={userId}
        />
      </Modal>
    </>
  );
};

export default UsuariosPage;
