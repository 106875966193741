import { useState } from 'react';
import { API_BASE_URL } from '../config/apiConfig';

export interface Cliente {
    //id_cliente_erp?: string;
    nombres: string;
    tipo_doc: string;
    numero_doc: string;
    correo: string;
    celular: string;
    tipo_persona?: string;
    regimen?: string;
    id_pais?: number;
    id_departamento?: number;
    id_municipio?: number;
    direccion?: string;
}

export interface Vehiculo {
    placa: string;
    tipo_vehiculo: number;
    cliente?: number;
}

export interface UpsertVehiculoClienteResponse {
    success: boolean;
    error?: string;
}

const useUpsertVehiculoCliente = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [success, setSuccess] = useState<boolean | null>(null);

    const upsertVehiculoCliente = async (
        clienteData: Cliente,
        vehiculoData: Vehiculo
    ) => {
        setLoading(true);
        setError(null);
        setSuccess(null);

        try {
            const response = await fetch(`${API_BASE_URL}/vehiculo`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    clienteData,
                    vehiculoData
                })
            });

            const data: UpsertVehiculoClienteResponse = await response.json();

            if (data.success) {
                setSuccess(true);
            } else {
                setError(data.error || 'Error desconocido');
            }
        } catch (err) {
            setError('Error al conectar con el servidor');
        } finally {
            setLoading(false);
        }
    };

    return {
        upsertVehiculoCliente,
        loading,
        error,
        success
    };
};

export default useUpsertVehiculoCliente;