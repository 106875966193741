import React, { useState, useEffect, useRef } from 'react';
import { Button, Table, Input, Tag, Card } from 'antd';
import { FileExcelOutlined, FilterFilled, FilterOutlined, SyncOutlined } from '@ant-design/icons';
import { ColumnProps } from 'antd/es/table';
import useObtenerIngresos from '../../hooks/useObtenerIngresos';
import moment from 'moment';
import './IngresosTable.scss';
import * as XLSX from 'xlsx';

interface InformeTableProps {
  id?: string | null;
  fecha_inicio: string;
  fecha_fin: string;
  estados: string[];
  setTotalRegistros: (value: number) => void;
  setTotalValor: (value: number) => void;
  onOpenModal: () => void;
  resetFiltersAndReload: () => void;
}

const InformeTable: React.FC<InformeTableProps> = ({ id, fecha_inicio, fecha_fin, estados, setTotalRegistros, setTotalValor, onOpenModal, resetFiltersAndReload }) => {
  const { ingresos, cargando, error } = useObtenerIngresos(id, fecha_inicio, fecha_fin, estados);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [activeFilters, setActiveFilters] = useState({});
  const tableDataRef = useRef<any[]>([]);

  const tipoEstado = [
    { text: 'Ingresado', value: 'ingresado' },
    { text: 'Entregado', value: 'entregado' },
    { text: 'Anulado', value: 'anulado' }
  ];

  const sucursales = [
    { text: 'Bello', value: 'Bello' },
    { text: 'Envigado', value: 'Envigado' },
    { text: 'Laureles', value: 'Laureles' },
    { text: 'Oviedo', value: 'Oviedo' },
    { text: 'Robledo', value: 'Robledo' },
    { text: 'San Lucas', value: 'San Lucas' },
  ];

  useEffect(() => {
    if (ingresos) {
      tableDataRef.current = ingresos;
      applyFiltersAndSearch(ingresos, searchTerm, activeFilters);
    }
  }, [ingresos, searchTerm, activeFilters]);

  const handleSearch = (value: string) => {
    setSearchTerm(value.toLowerCase());
  };

  const applyFiltersAndSearch = (data: any[], search: string, filters: any) => {
    let filtered = data;

    if (search) {
      filtered = filtered.filter(item =>
        item.sucursal.toLowerCase().includes(search) ||
        item.vehiculo_placa.toLowerCase().includes(search) ||
        item.cliente_nombre.toLowerCase().includes(search) ||
        item.servicios.toLowerCase().includes(search)
      );
    }

    if (filters.sucursal && filters.sucursal.length > 0) {
      filtered = filtered.filter(item => filters.sucursal.includes(item.sucursal));
    }

    if (filters.estado && filters.estado.length > 0) {
      filtered = filtered.filter(item => filters.estado.includes(item.estado));
    }

    setFilteredData(filtered);
    updateTotals(filtered);
  };

  const updateTotals = (data: any[]) => {
    setTotalRegistros(data.length);
    const sumaTotal = data.reduce((acc, curr) => {
      const valor = Number(curr.valor_total) || 0;
      return acc + valor;
    }, 0);
    setTotalValor(sumaTotal);
  };

  const handleTableChange = (pagination, filters, sorter, extra) => {
    setActiveFilters(filters);
  };

  const handleDownloadExcel = () => {
    const data = filteredData.length ? filteredData : ingresos;

    const formattedData = data.map((row: any) => ({
      Ingreso: row.ingreso_id,
      Sucursal: row.sucursal,
      Placa: row.vehiculo_placa,
      Servicios: row.servicios,
      Valor: row.valor_total,
      Cliente: row.cliente_nombre,
      Celular: row.celular,
      Estado: row.estado,
      FechaCreación: moment(row.fecha_creacion).format('DD/MM/YYYY HH:mm:ss')
    }));

    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Datos Filtrados');
    XLSX.writeFile(workbook, 'Aprisa_Reporte.xlsx');
  };

  const columns: ColumnProps<any>[] = [
    {
      key: 'ingreso_id',
      dataIndex: 'ingreso_id',
      title: 'Ingreso'
    },
    {
      key: 'sucursal',
      dataIndex: 'sucursal',
      title: 'Sucursal',
      filters: sucursales,
      onFilter: (value: string | number | boolean, record: any) => record.sucursal === value,
      filterMultiple: true,
      filterIcon: (filtered: boolean) => (
        <FilterFilled style={{ color: filtered ? '#ff4d4f' : '#ff4d4f' }} />
      )
    },
    {
      key: 'placa',
      dataIndex: 'vehiculo_placa',
      title: 'Placa'
    },
    {
      key: 'servicios',
      dataIndex: 'servicios',
      title: 'Servicios',
      render: (text: string) => <span style={{ fontSize: '10px' }}>{text}</span>
    },
    {
      key: 'valor_total',
      dataIndex: 'valor_total',
      title: 'Valor',
      render: (valor: number) => (
        <div style={{ textAlign: 'right' }}>
          <span className='nowrap' style={{ color: '#336cfb' }}>
            {new Intl.NumberFormat('es-CO', { style: 'currency', currency: 'COP' }).format(valor)}
          </span>
        </div>
      )
    },
    {
      key: 'cliente_nombre',
      dataIndex: 'cliente_nombre',
      title: 'Cliente'
    },
    {
      key: 'celular',
      dataIndex: 'celular',
      title: 'Celular',
      render: (phone: string) => (
        <span className='d-flex align-baseline nowrap' style={{ color: '#336cfb' }}>
          <span className='icofont icofont-ui-cell-phone mr-1' style={{ fontSize: 16 }} /> {phone} 
        </span>
      )
    },
    {
      key: 'estado',
      dataIndex: 'estado',
      title: 'Estado',
      filters: tipoEstado,
      onFilter: (value: string | number | boolean, record: any) => record.estado === value,
      filterMultiple: true,
      render: (estado: string) => {
        let color;
        switch (estado) {
          case 'ingresado':
            color = '#FFC300';
            break;
          case 'entregado':
            color = '#b7ce63';
            break;
          case 'anulado':
            color = '#ff7f7f';
            break;
          default:
            color = '#d9d9d9';
        }

        return (
          <Tag style={{ borderRadius: 20 }} color={color}>
            {estado}
          </Tag>
        );
      },
      filterIcon: (filtered: boolean) => (
        <FilterFilled style={{ color: filtered ? '#ff4d4f' : '#ff4d4f' }} />
      )
    },
    {
      key: 'fecha_creacion',
      dataIndex: 'fecha_creacion',
      title: 'Fecha Creación',
      render: (fecha: string) => {
        const formattedDate = moment.utc(fecha).format('DD/MM/YYYY HH:mm:ss');
        return <span style={{ fontSize: '10px' }}>{formattedDate}</span>;
      }
    }
  ];

  if (error) {
    return <p>Error al cargar los datos: {error.message}</p>;
  }

  const pagination = ingresos?.length <= 10 ? false : {};

  return (
    <>

      <div className='row'>
        <div className='mb-0'>
          <Button
            type='primary'
            onClick={onOpenModal}
            icon={<FilterOutlined />}
            style={{ width: '40px', marginBottom: '10px', marginLeft: '10px' }}
          />
          <Button
            type='primary'
            onClick={resetFiltersAndReload}
            icon={<SyncOutlined />}
            style={{ width: '40px', marginBottom: '10px', marginLeft: '20px' }}
          />
          <Button
            type='primary'
            onClick={handleDownloadExcel}
            icon={<FileExcelOutlined />}
            style={{ width: '40px', marginBottom: '10px', marginLeft: '20px', backgroundColor: '#28a745', borderColor: '#28a745' }}
          />
        </div>
        <div className='col-md-2 col-sm-12' style={{ position: 'relative' }}>
          <Input
            placeholder='Buscar...'
            style={{ marginBottom: '10px'}}
            onChange={(e) => handleSearch(e.target.value)}
          />
        </div>
      </div>
      <Table
        loading={cargando}
        className='accent-header'
        dataSource={filteredData}
        columns={columns}
        rowKey='id'
        pagination={pagination}
        onChange={handleTableChange}
      />
    
    </>
  );
};

export default InformeTable;









