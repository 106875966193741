import React, { useMemo, useState } from 'react';
import { Button, Table, Input, Card } from 'antd';
import { FormOutlined, PlusSquareOutlined } from '@ant-design/icons';
import { ColumnProps } from 'antd/es/table';
import { useNavigate } from 'react-router-dom';
import { ColumnFilterItem } from 'antd/es/table/interface';

interface Vehiculo {
  id: number;
  placa: string;
  tipo_vehiculo: string;
  documento_cliente: string;
  nombre_cliente: string;
}

const VehiculosTable = ({ vehiculosAll }: { vehiculosAll: Vehiculo[] }) => {
  const [searchText, setSearchText] = useState('');
  const [filteredData, setFilteredData] = useState<Vehiculo[]>(vehiculosAll);
  const navigate = useNavigate();


  const handleCrearVehiculo = () => {
    navigate('/vertical/crear-vehiculoCliente');
  };

  const handleEditarInfo = (vehiculo: Vehiculo) => {
    navigate(`/vertical/editar-vehiculoCliente/${vehiculo.placa}`);
    console.log('Navegando a editar vehiculo con Placa:', vehiculo.placa);
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchText(value);
    const filtered = vehiculosAll.filter(
      (vehiculo) =>
        vehiculo.placa.toLowerCase().includes(value.toLowerCase()) ||
        vehiculo.tipo_vehiculo.toLowerCase().includes(value.toLowerCase()) ||
        vehiculo.documento_cliente.toLowerCase().includes(value.toLowerCase()) ||
        vehiculo.nombre_cliente.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredData(filtered);
  };

  const tipoVehiculoOptions: ColumnFilterItem[] = useMemo(() => {
    const uniqueTypes = new Set(vehiculosAll.map((vehiculo) => vehiculo.tipo_vehiculo));
    return Array.from(uniqueTypes)
      .filter((type): type is string => type !== undefined)
      .map((type) => ({
        text: type as React.ReactNode,
        value: type,
      }));
  }, [vehiculosAll]);

  const columns: ColumnProps<Vehiculo>[] = [
    {
      key: 'actions',
      title: 'Acciones',
      render: (vehiculo: Vehiculo) => (
        <Button
          icon={<FormOutlined />}
          shape='circle'
          danger
          onClick={() => handleEditarInfo(vehiculo)}
        ></Button>
      ),
    },
    {
      key: 'id',
      dataIndex: 'id',
      title: 'Id',
      sorter: (a, b) => a.id - b.id,
      render: (id: number) => <span className='nowrap'>{id}</span>,
    },
    {
      key: 'placa',
      dataIndex: 'placa',
      title: 'Placa',
      render: (placa: string) => <span className='nowrap'>{placa}</span>,
    },
    {
      key: 'tipo_vehiculo',
      dataIndex: 'tipo_vehiculo',
      title: 'Tipo Vehículo',
      filters: tipoVehiculoOptions,
      onFilter: (value, record) => record.tipo_vehiculo === value,
      filterMultiple: true,
      render: (tipo_vehiculo: string) => <span className='nowrap'>{tipo_vehiculo}</span>,
    },
    {
      key: 'documento_cliente',
      dataIndex: 'documento_cliente',
      title: 'Documento Cliente',
      render: (documento_cliente: string) => <span className='nowrap'>{documento_cliente}</span>,
    },
    {
      key: 'nombre_cliente',
      dataIndex: 'nombre_cliente',
      title: 'Nombre Cliente',
      render: (nombre_cliente: string) => <span className='nowrap'>{nombre_cliente}</span>,
    },
  ];

  const pagination = vehiculosAll.length <= 10 ? false : {};

  return (
    <>
    <Card style={{ background: 'rgba(251, 251, 251)' }}
            className='animated with-shadow'>
      <div style={{ display: 'flex', marginBottom: 20 }}>
        <Button
          type='primary'
          icon={<PlusSquareOutlined />}
          onClick={handleCrearVehiculo}
          style={{ marginRight: 8 }}
        >
          Crear Vehículo
        </Button>
        <Input
          placeholder='Buscar...'
          value={searchText}
          onChange={handleSearch}
          style={{ width: 300 }}
        />
      </div>
      <Table
        pagination={pagination}
        rowKey='placa'
        dataSource={filteredData}
        columns={columns}        
      />
      </Card>
    </>
  );
};

export default VehiculosTable;






