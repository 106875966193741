import { notification } from 'antd';
import { IMAGE_SERVER_URL } from "../../../config/apiConfig";

export const handleSaveImage = (stageRef, placa) => {
  const canvas = document.getElementById('canvasId') as HTMLCanvasElement;
  const stage = stageRef.current;
  console.log(stageRef)
  if (canvas && stage) {
    try {
      const ctx = canvas.getContext('2d');
      if (ctx) {
        const dataURL = stage.toDataURL();
        const img = new Image();
        img.crossOrigin = 'anonymous';
        img.src = dataURL;
        img.onload = () => {
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          ctx.drawImage(img, 0, 0);

          const finalDataURL = canvas.toDataURL('image/png');
          const filename = `imagen_${placa}.png`;

          fetch(`${IMAGE_SERVER_URL}/save-image`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ imageData: finalDataURL, filename })
          })
            .then((response) => {
              if (!response.ok) {
                throw new Error('Error de conexión en la respuesta.');
              }
              /* notification.success({
                message: 'Imagen guardada exitosamente'
              });
              return response.text(); */
            })
            .catch((error) => {
              notification.error({
                message: 'Error al guardar la imagen'
              });
              console.error('Error guardando la imagen:', error);
            });
        };
      } else {
        notification.error({
          message: 'Error al obtener la imagen'
        });
        console.error('No se pudo obtener canvas');
      }
    } catch (error) {
      notification.error({
        message: 'Error al exportar la imagen'
      });
      console.error('Error exportando en canvas:', error);
    }
  } else {
    console.error('No se encontró el canvas con la placa');
  }
};
