import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import ClienteForm from './clienteForm';

const ClienteRapido: React.FC<{ onAddCliente: (nuevoCliente: any) => void }> = ({
  onAddCliente
}) => {
  const [modalVisible, setModalVisible] = useState(false);

  const handleClick = () => {
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  const handleAddCliente = (nuevoCliente) => {
    onAddCliente(nuevoCliente);
    handleCloseModal();
  };

  return (
    <div className='add-patient'>
      <Button type='primary' onClick={handleClick}>
        <span className='icofont icofont-plus mr-2' style={{ fontSize: '1.3em' }} />
        Cliente Rápido 
      </Button>
      <Modal
        open={modalVisible}
        footer={null}
        onCancel={handleCloseModal}
        destroyOnClose
        title={<h3 className='title'>Crear Cliente Rápido</h3>}
        maskClosable={false}
      >
        <ClienteForm onAddCliente={handleAddCliente} onClose={handleCloseModal}></ClienteForm>
      </Modal>
    </div>
  );
};

export default ClienteRapido;
