import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Select } from 'antd';
import { useSucursales } from '../../hooks/useSucursales';
import { useInsertUser } from '../../hooks/useInsertarUser';
import { useAlert } from '../../context/AlertContext';
import { IUsuarioAll3 } from '../../interfaces/usuarios';
import { useUpdateUser } from '../../hooks/useUpdateUser';

const UsuarioForm: React.FC<{
  onSubmit: (values: any) => void;
  onClose: () => void;
  initialData: IUsuarioAll3 | null;
  userId: string;
}> = ({ onSubmit, onClose, initialData, userId }) => {
  const { sucursales, loading } = useSucursales();
  const { insertUser } = useInsertUser();
  const { updateUser } = useUpdateUser();
  const alertContext = useAlert();

  const [formData, setFormData] = useState<IUsuarioAll3>({
    id: 0,
    empleado: 0,
    responsable: '',
    sucursal: 0,
    rol: '',
    correo: '',
    clave: '',
    estado: '',
    celular: ''
  });

  useEffect(() => {
    console.log('Cargando datos iniciales: ', initialData);

    if (initialData) {
      const sucursalId =
        sucursales.find((s) => s.descripcion === initialData.sucursal)?.id || initialData.sucursal;
      setFormData({
        id: initialData.id,
        empleado: initialData.empleado || null,
        responsable: initialData.responsable,
        sucursal: sucursalId,
        rol: initialData.rol,
        correo: initialData.correo,
        clave: '',
        estado: initialData.estado,
        celular: initialData.celular || ''
      });
    } else {
      setFormData({
        id: 0,
        empleado: 0,
        responsable: '',
        sucursal: 0,
        rol: '',
        correo: '',
        clave: '',
        estado: '',
        celular: ''
      });
    }
  }, [initialData, sucursales]);

  const handleChange = (name, value) => {
    console.log(`Cambio detectado: ${name} = ${value}`);
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async () => {
    let dataToSend = { ...formData };

    if (initialData) {
      if (dataToSend.clave === '') {
        delete dataToSend.clave;
      }
    } else {
      if (dataToSend.clave === '') {
        alertContext.triggerAlert({
          type: 'error',
          message: 'La clave no puede estar vacía al crear un usuario',
          description: 'Debe proporcionar una clave al crear un usuario.'
        });
        return;
      }
    }

    const action = initialData ? updateUser : insertUser;
    const result = await action(dataToSend);
    if (result && result.error) {
      alertContext.triggerAlert({
        type: 'error',
        message: `Error al ${initialData ? 'actualizar' : 'crear'} el usuario`,
        description: result.error
      });
    } else {
      alertContext.triggerAlert({
        type: 'success',
        message: `Usuario ${initialData ? 'actualizado' : 'creado'} correctamente`,
        description: `Usuario ${initialData ? 'actualizado' : 'creado'} correctamente`
      });
      onClose();
      onSubmit(formData);
    }
  };

  return (
    <>
      <Form
        key={initialData ? 'edit' : 'create'}
        layout='vertical'
        onFinish={handleSubmit}
        initialValues={initialData}
      >
        <div className='row'>
          <div className='col-md-6 col-sm-12'>
            <Form.Item
              label='Usuario'
              name='responsable'
              rules={[
                {
                  required: true,
                  message: 'El nombre del usuario es obligatorio'
                },
                {
                  min: 5,
                  message: 'El nombre del usuario debe tener al menos 5 caracteres'
                }
              ]}
            >
              <Input
                placeholder='Nombre Usuario'
                onChange={(e) => handleChange('responsable', e.target.value)}
                value={formData.responsable}
                autoComplete='off'
              />
            </Form.Item>
          </div>
          <div className='col-md-6 col-sm-12'>
            <Form.Item
              label='Id Erp'
              name='empleado'
              rules={[
                {
                  validator: (_, value) =>
                    value === undefined || value >= 0
                      ? Promise.resolve()
                      : Promise.reject(new Error('El Id_ERP debe no puede ser negativo'))
                }
              ]}
            >
              <Input
                type='number'
                placeholder='ID empleado ERP es numérico'
                onChange={(e) => handleChange('empleado', e.target.value)}
                value={formData.empleado.toString()}
                autoComplete='off'
              />
            </Form.Item>
          </div>
        </div>

        <Form.Item
          label='Sucursal'
          name='sucursal'
          rules={[
            {
              required: true,
              message: 'La sucursal es obligatoria'
            }
          ]}
        >
          <Select
            onChange={(value) => handleChange('sucursal', value)}  
            value={formData.sucursal}
            placeholder='Seleccione una sucursal'
            loading={loading}
          >
            {sucursales.map((sucursal) => (
              <Select.Option key={sucursal.id} value={sucursal.id}>
                {sucursal.descripcion}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <div className='row'>
          <div className='col-md-6 col-sm-12'>
            <Form.Item
              label='Tipo Usuario'
              name='rol'
              rules={[{ required: true, message: 'Seleccionar un rol es obligatorio' }]}
            >
              <Select
                onChange={(value) => handleChange('rol', value)}
                value={formData.rol}
                placeholder='Seleccione un rol'
              >
                <Select.Option value='admin'>Admin</Select.Option>
                <Select.Option value='user'>User</Select.Option>
              </Select>
            </Form.Item>
          </div>
          <div className='col-md-6 col-sm-12'>
            <Form.Item label='Celular' name='celular'>
              <Input
                placeholder='# Celular'
                onChange={(e) => handleChange('celular', e.target.value)}
                value={formData.celular}
                autoComplete='off'
              />
            </Form.Item>
          </div>
        </div>

        <Form.Item
          label='Correo'
          name='correo'
          rules={[
            {
              type: 'email',
              message: 'El correo electrónico no es válido'
            },
            {
              required: true,
              message: 'El correo electrónico es obligatorio'
            }
          ]}
        >
          <Input
            type='email'
            placeholder='Correo Electrónico'
            onChange={(e) => handleChange('correo', e.target.value)}
            value={formData.correo}
          />
        </Form.Item>

        <div className='row'>
          <div className='col-md-6 col-sm-12'>
            <Form.Item
              label='Estado'
              name='estado'
              rules={[
                {
                  required: true,
                  message: 'El estado es obligatorio'
                }
              ]}
            >
              <Select
                onChange={(value) => handleChange('estado', value)}
                value={formData.estado}
                placeholder='Seleccione un estado'
              >
                <Select.Option value='Activo'>Activo</Select.Option>
                <Select.Option value='Inactivo'>Inactivo</Select.Option>
              </Select>
            </Form.Item>
          </div>
          <div className='col-md-6 col-sm-12'>
            <Form.Item
              label='Clave'
              name='clave'
              rules={[
                {
                  required: !initialData,
                  message: 'La clave es obligatoria'
                },
                {
                  min: 4,
                  message: 'La clave debe tener al menos 4 dígitos'
                }
              ]}
            >
              <Input.Password
                placeholder='Escribir clave'
                onChange={(e) => handleChange('clave', e.target.value)}
                value={formData.clave}
                autoComplete='new-password'
              />
            </Form.Item>
          </div>
        </div>

        <div className='modal-footer d-flex justify-content-between mt-3'>
          <Button danger onClick={onClose}>
            Cancelar
          </Button>
          <Button type='primary' htmlType='submit'>
            {initialData ? 'Actualizar' : 'Guardar'}
          </Button>
        </div>
      </Form>
    </>
  );
};

export default UsuarioForm;
