import { Button, DatePicker, Form, Select } from 'antd';
import React, { useState } from 'react';
import moment from 'moment';

const FiltroForm: React.FC<{
  onClose: () => void;
  onFiltro: (estado: string[], fechaInicio: moment.Moment, fechaFin: moment.Moment) => void;
}> = ({ onClose, onFiltro }) => {
  const [estado, setEstado] = useState([]);
  const [fechaInicio, setFechaInicio] = useState(null);
  const [fechaFin, setFechaFin] = useState(null);

  const handleEstadoChange = (value) => {
    if (value === 'todos') {
      setEstado(['ingresado', 'entregado', 'anulado']);
    } else {
      setEstado([value]);
    }
  };

  const handleBuscar = () => {
    if (fechaInicio && fechaFin && estado.length > 0) {
      const startOfDay = fechaInicio.startOf('day');
      const endOfDay = fechaFin.endOf('day');
      onFiltro(estado, startOfDay, endOfDay);
    }
    onClose();
  };

  const handleCancel = () => {
    onClose();
  };

  return (
    <Form layout='vertical' onFinish={handleBuscar}>
      <Form.Item label='Estado' rules={[{ required: true, message: 'El estado es obligatorio' }]}>
        <Select
          placeholder='Seleccione un Estado'
          value={estado.length === 3 ? 'todos' : estado[0]}
          onChange={handleEstadoChange}
        >
          <Select.Option value='ingresado'>Ingresados</Select.Option>
          <Select.Option value='entregado'>Entregados</Select.Option>
          <Select.Option value='anulado'>Anulados</Select.Option>
          <Select.Option value='todos'>Todos</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item label='Fecha Inicio'>
        <DatePicker 
        inputReadOnly
        onChange={(date) => setFechaInicio(date)} format='YYYY-MM-DD' />
      </Form.Item>
      <Form.Item label='Fecha Fin'>
        <DatePicker 
        inputReadOnly
        onChange={(date) => setFechaFin(date)} format='YYYY-MM-DD' />
      </Form.Item>
      <div className='modal-footer d-flex justify-content-between mt-3'>
        <Button danger onClick={handleCancel}>
          Cancelar
        </Button>
        <Button type='primary' htmlType='submit'>
          Buscar
        </Button>
      </div>
    </Form>
  );
};

export default FiltroForm;
