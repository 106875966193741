import { useEffect, useState } from 'react';
import { API_BASE_URL } from '../config/apiConfig';

interface Vehiculo {
  placa: string;
  id_categoria: string;
}

export function useGetPlacaByPlaca(placa: string) {
  const [data, setData] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!placa) {
      setData([]);
      setLoading(false);
      return;
    }

    const fetchPlaca = async () => {
      setLoading(true);
      try {
        console.log(`Obteniendo PLaca para: ${placa}`);
        const response = await fetch(`${API_BASE_URL}/vehiculo?placa=${placa}`, { 
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const result = await response.json();
        console.log(`Obteniendo Placa:`, result);
        if (result.vehiculo) {
          setData(result.vehiculo.slice(0, 10).map((vehiculo: Vehiculo) => vehiculo.placa));
        } else {
          setData([]);
        }
      } catch (error: any) {
        console.error(`Error al obtener la placa: ${error.message}`);
        setError(`Error al obtener la placa: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchPlaca();
  }, [placa]);

  return { data, loading, error };
}

