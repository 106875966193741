import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useUser } from '../../hooks/useUser';
import { useAllProductos } from '../../hooks/useAllProductos';
import IngresosTable from './InformeTable';
import { usePageData } from '../../hooks/usePage';
import { Card, Modal, Spin } from 'antd';
import FiltroForm from './FiltroInformeForm';
import moment from 'moment';

const InformePage: React.FC = () => {
 
  usePageData({ title: 'Informes', fulFilled: true });
  const { loading } = useAllProductos();
  const { isAuthenticated, role } = useUser();
  const [totalRegistros, setTotalRegistros] = useState(0);
  const [totalValor, setTotalValor] = useState(0);
  const allowedRoles = ['admin'];

  const [fechaInicio, setFechaInicio] = useState(
    moment().startOf('day').format('YYYY-MM-DD HH:mm:ss')
  );
  const [fechaFin, setFechaFin] = useState(moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'));
  const [estados, setEstados] = useState(['ingresado', 'entregado', 'anulado']);

  const resetFiltersAndReload = () => {
    setFechaInicio(moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'));
    setFechaFin(moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'));
    setEstados(['ingresado', 'entregado', 'anulado']);
  };

  const handleFiltro = (estado: string[], fechaInicio: moment.Moment, fechaFin: moment.Moment) => {
    setEstados(estado);
    setFechaInicio(fechaInicio.format('YYYY-MM-DD HH:mm:ss'));
    setFechaFin(fechaFin.format('YYYY-MM-DD HH:mm:ss'));
    toggleModal();
  };

  const [isModalVisible, setIsModalVisible] = useState(false);
  const toggleModal = () => {
    setIsModalVisible(!isModalVisible);
  };

  if (!isAuthenticated || !allowedRoles.includes(role)) {
    return <Navigate to='/public/page-404' replace />;
  }

  if (loading ) {
    return (
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh' 
      }}>
        <Spin size='large' tip='Cargando...' />
      </div>
    );
  }
  return (
    <>
     <Card style={{ background: 'rgba(251, 251, 251)' }}
            className='animated with-shadow'>
      <IngresosTable
        id={null} 
        fecha_inicio={fechaInicio}
        fecha_fin={fechaFin}
        estados={estados}
        setTotalRegistros={setTotalRegistros}
        setTotalValor={setTotalValor}
        onOpenModal={toggleModal}
        resetFiltersAndReload={resetFiltersAndReload}
      />
      <br />
      <div className='row'>
        <div className='col-12 col-md-6 col-xl-3'>
          <Card
            style={{ background: 'rgba(251, 251, 251)', border: '1px solid royalblue' }}
            className='animated with-shadow'
          >
            <div className='row'>
              <div className='col-5'>
                <span
                  className='icofont-database-add'
                  style={{ fontSize: 48, color: 'rgba(51, 108, 251, 0.5)' }}
                />
              </div>

              <div className='col-7'>
                <h6 className='mt-0 mb-1'>Registros</h6>
                <div className='count' style={{ fontSize: 20, color: '#336cfb', lineHeight: 1.4 }}>
                  {totalRegistros}
                </div>
              </div>
            </div>
          </Card>
        </div>

        <div className='col-12 col-md-6 col-xl-3'>
          <Card
            style={{ background: 'rgba(251, 251, 251)', border: '1px solid royalblue' }}
            className='animated with-shadow'
          >
            <div className='row'>
              <div className='col-5'>
                <span
                  className='icofont-money'
                  style={{ fontSize: 48, color: 'rgba(51, 108, 251, 0.5)' }}
                />
              </div>

              <div className='col-7'>
                <h6 className='mt-0 mb-1'>Valor Total</h6>
                <div className='count' style={{ fontSize: 20, color: '#336cfb', lineHeight: 1.4 }}>
                  {new Intl.NumberFormat('es-CO', { style: 'currency', currency: 'COP' }).format(
                    totalValor
                  )}
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>
      <div className='add-patient'>
        {isModalVisible && (
          <Modal
            open={isModalVisible}
            footer={null}
            onCancel={toggleModal}
            destroyOnClose
            title={<h4 className='title'>Filtrar Tabla</h4>}
            maskClosable={false}
          >
            <FiltroForm onClose={toggleModal} onFiltro={handleFiltro} />
          </Modal>
        )}
      </div>
      </Card>
    </>
  );
};

export default InformePage;




