import React, { useImperativeHandle } from 'react';
import { Form, Input, InputNumber, Select } from 'antd';
import { useParams } from 'react-router-dom';
import { useGetProductoById } from '../../hooks/useGetProductoById';
import { useInsertProducto } from '../../hooks/useInsertProducto';
import { useActualizarProducto } from '../../hooks/useActualizarProducto';
import { useAlert } from '../../context/AlertContext';

const { Item: FormItem } = Form;
const { Option } = Select;

export interface ProfileFormMethods {
  submitForm: () => void;
}

const ProfileFormProducto = React.forwardRef<ProfileFormMethods, {}>((props, ref) => { 
  const { id } = useParams();
  const [form] = Form.useForm();
  const { producto, loading, error } = useGetProductoById(id);
  const { insertProducto } = useInsertProducto();
  const { updateProducto } = useActualizarProducto();
  const alertContext = useAlert();

  useImperativeHandle(ref, () => ({
    submitForm: () => form.submit()
  }));

  const handleSubmit = async (values) => {
    const result = id ? await updateProducto({ ...values, id }) : await insertProducto(values);
    if (result && result.success) {
      alertContext.triggerAlert({
        type: 'error',
        message: ` Error ${id ? 'Actualizando' : 'Insertando'} Producto`,
        description: 'Módulo Productos'
      });
    } else {
      alertContext.triggerAlert({
        type: 'success',
        message: `${id ? 'Producto Actualizado' : 'Cliente Insertado'} con éxito`,
        description: 'Módulo Productos'
      });
    }
  };

  if (loading) return <p>Cargando...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <>
    
    <Form
      form={form}
      layout='vertical'
      onFinish={handleSubmit}
      initialValues={producto || { impuesto: 19 }}
    >
      <div className='row'>
        <div className='col-md-2 col-sm-12'>
          <FormItem initialValue={producto?.id_erp} name='id_erp' label='Id ERP'> 
            <Input placeholder='Id del ERP' />
          </FormItem>
        </div>
        <div className='col-md-3 col-sm-12'>
          <FormItem
            initialValue={producto?.referencia}
            name='referencia'
            label='Referencia'
            rules={[{ required: true, message: 'La referencia es Obligatoria' }]}
          >
            <Input placeholder='Referencia' />
          </FormItem>
        </div>
        <div className='col-md-7 col-sm-12'>
          <FormItem
            initialValue={producto?.descripcion}
            name='descripcion'
            label='Descripción'
            rules={[{ required: true, message: 'La Descripción es Obligatoria' }]}
          >
            <Input placeholder='Descripción del Producto' />
          </FormItem>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-6 col-sm-12'>
          <FormItem initialValue={producto?.observacion} name='observacion' label='Observación'>
            <Input placeholder='Observación' />
          </FormItem>
        </div>
        <div className='col-md-2 col-sm-12'>
          <FormItem
            name='id_categoria'
            label='Categoría'
            initialValue={producto?.id_categoria}
            rules={[{ required: true, message: 'Seleccione un tipo de Categoría' }]}
          >
            <Select placeholder='Categoría'>
              <Option value='1'>Automovil</Option>
              <Option value='2'>Moto</Option>
              <Option value='3'>Campero</Option>
              <Option value='4'>Camioneta</Option>
            </Select>
          </FormItem>
        </div>

        <div className='col-md-2 col-sm-12'>
          <FormItem initialValue={producto?.impuesto} name='impuesto' label='Impuesto'>
            <InputNumber
              formatter={(value) => `${value}%`}
              placeholder='Impuesto'
              style={{ width: '100%', height: '40px', alignContent: 'center' }}
              min={0}
              max={100}
            />
          </FormItem>
        </div>
        <div className='col-md-2 col-sm-12'>
          <FormItem initialValue={producto?.valor} name='valor' label='Precio Base'>
            <InputNumber
              formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              placeholder='Precio del Producto'
              style={{ width: '100%', height: '40px', alignContent: 'center' }}
            />
          </FormItem>
        </div>
      </div>
      <div className='row'></div>
    </Form>
    </>
  );
});

export default ProfileFormProducto;
