import React, { ReactNode,  useMemo, useState } from 'react';
import { Button, Table, Input, Card } from 'antd';
import { FormOutlined, PlusSquareOutlined } from '@ant-design/icons';
import { ColumnProps } from 'antd/es/table';
import { IProductoAll } from '../../interfaces/productos';
import { useNavigate } from 'react-router-dom';
import { ColumnFilterItem } from 'antd/es/table/interface';

const ProductosTable = ({ productosAll }) => {
  const [searchText, setSearchText] = useState('');
  const [filteredData, setFilteredData] = useState(productosAll);
  const navigate = useNavigate();

  const handleCrearProducto = () => {
    navigate('/vertical/crear-articulo');
  };

  const handleEditarInfo = (producto) => {
    //setTimeout(() => {
      navigate(`/vertical/editar-articulo/${producto.id}`);
      console.log('Navegando a editar articulo con Id:', producto.id); 
    //}, 200);
  };

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchText(value);
    const filtered = productosAll.filter(
      (producto) =>
        (producto.descrip_prod
          ? producto.descrip_prod.toLowerCase().includes(value.toLowerCase())
          : false) ||
        (producto.referencia
          ? producto.referencia.toLowerCase().includes(value.toLowerCase())
          : false)
    );
    setFilteredData(filtered);
  };

  
  const tipoVehiculoOptions: ColumnFilterItem[] = useMemo(() => {
    const uniqueTypes = new Set(productosAll.map((producto) => producto.tipo_vehiculo));
    return Array.from(uniqueTypes)
      .filter((type): type is string => type !== undefined)
      .map((type) => ({
        text: type as ReactNode,
        value: type
      }));
  }, [productosAll]);

  const columns: ColumnProps<IProductoAll>[] = [
    {
      key: 'actions',
      title: 'Acciones',
      render: (producto) => (
        <Button
          icon={<FormOutlined />}
          shape='circle'
          danger
          onClick={() => handleEditarInfo(producto)}
        ></Button>
      )
    },
    {
      key: 'id',
      dataIndex: 'id',
      title: 'Id',
      sorter: (a, b) => a.id - b.id,
      render: (id) => <span className='nowrap'>{id}</span>
    },
    {
      key: 'referecnia',
      dataIndex: 'referencia',
      title: 'Referencia',
      render: (referencia) => <span className='nowrap'>{referencia}</span>
    },
    {
      key: 'id_erp',
      dataIndex: 'id_erp',
      title: 'ID en ERP',
      render: (id_erp) => <span className='nowrap'>{id_erp}</span>
    },
    {
      key: 'descrip_prod',
      dataIndex: 'descrip_prod',
      title: 'Descripcion'
    },
    {
      key: 'tipo_vehiculo',
      dataIndex: 'tipo_vehiculo',
      title: 'Tipo Vehículo',
      filters: tipoVehiculoOptions,
      onFilter: (value, record) => record.tipo_vehiculo === value,
      filterMultiple: true,
      render: (tipo_vehiculo) => <span className='nowrap'>{tipo_vehiculo}</span>
    },
    {
      key: 'valor',
      dataIndex: 'valor',
      title: 'Valor Unitario',
      render: (valor) => (
        <div style={{ textAlign: 'right' }}>
          <span className='nowrap' style={{ color: '#336cfb' }}>
            {new Intl.NumberFormat('es-CO', { style: 'currency', currency: 'COP' }).format(valor)}
          </span>
        </div>
      )
    }
  ];

  const pagination = productosAll.length <= 10 ? false : {};

  return (
    <>
    <Card style={{ background: 'rgba(251, 251, 251)' }}
            className='animated with-shadow'>
      <div style={{ display: 'flex', marginBottom: 20 }}>
        <Button
          type='primary'
          icon={<PlusSquareOutlined />}
          onClick={handleCrearProducto}
          style={{ marginRight: 8 }}
        >
          Crear Producto
        </Button>
        <Input
          placeholder='Buscar Producto...'
          value={searchText}
          onChange={handleSearch}
          style={{ width: 300 }}
        />
      </div>
      <Table
        pagination={pagination}
        //className='accent-header'
        rowKey='id'
        dataSource={filteredData}
        columns={columns}
      />
      </Card>
    </>
  );
};

export default ProductosTable;
