import { useState, useEffect } from 'react';
import { API_BASE_URL } from '../config/apiConfig';

export const useAllUser = () => {
  const [usuariosAll, setUsuariosAll] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUsuarios = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${API_BASE_URL}/usuario`);
        if (!response.ok) {
          throw new Error(`API call failed with status: ${response.status}`);
        }
        const data = await response.json();
        setUsuariosAll(data);
      } catch (error) {
        console.error('Error Obteniendo Usuarios:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUsuarios();
  }, []);

  const addUser = (newUser) => {
    setUsuariosAll(prev => [...prev, newUser]);
  };

  const updateUser = (updatedUser) => {
    setUsuariosAll(prev => prev.map(user => user.id === updatedUser.id ? { ...user, ...updatedUser } : user));
  };

  return { usuariosAll, loading, addUser, updateUser };
};