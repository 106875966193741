import React, { useState } from 'react';
import { Button, Table, Input, Card } from 'antd';
import { UserAddOutlined } from '@ant-design/icons';
import { ColumnProps } from 'antd/es/table';
import { IClienteAll } from '../../../interfaces/clientes';
import { useNavigate } from 'react-router-dom';

const ClientesTable = ({ clientesAll, onEditarCliente }) => {
  const [searchText, setSearchText] = useState('');
  const [filteredData, setFilteredData] = useState(clientesAll);
  const navigate = useNavigate();

  const handleCrearCliente = () => {
    navigate('/vertical/perfil-clientes');
  };

  const handleEditarInfo = (cliente) => {
    navigate(`/vertical/perfil-clientes/${cliente.id}`);
  };

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchText(value);
    const filtered = clientesAll.filter(
      (cliente) =>
        (cliente.nombres ? cliente.nombres.toLowerCase().includes(value.toLowerCase()) : false) ||
        (cliente.correo ? cliente.correo.toLowerCase().includes(value.toLowerCase()) : false) ||
        (cliente.numero_doc ? cliente.numero_doc.includes(value) : false)
    );
    setFilteredData(filtered);
  };
  const columns: ColumnProps<IClienteAll>[] = [
    {
      key: 'actions',
      title: 'Acciones',
      render: (text, cliente) => (
        <Button shape='circle' type='primary' onClick={() => handleEditarInfo(cliente)}>
          <span className='icofont icofont-edit-alt' />
        </Button>
      )
    },
    {
      key: 'id',
      dataIndex: 'id',
      title: 'Id',
      render: (id) => <span className='nowrap'>{id}</span>
    },
    {
      key: 'id_cliente_erp',
      dataIndex: 'id_cliente_erp',
      title: 'Id Cliente en ERP',
      sorter: (a, b) => a.id_cliente_erp - b.id_cliente_erp,
      render: (id_cliente_erp) => <strong>{id_cliente_erp}</strong>
    },
    {
      key: 'nombres',
      dataIndex: 'nombres',
      title: 'Nombre Cliente',
      sorter: (a, b) => a.nombres.localeCompare(b.nombres),
      render: (nombres) => <strong>{nombres}</strong>
    },
    {
      key: 'Tipo_doc',
      dataIndex: 'tipo_doc',
      title: 'Tipo Documento',
      sorter: (a, b) => a.tipo_doc.localeCompare(b.tipo_doc),
      render: (tipo_doc) => <span className='nowrap'>{tipo_doc}</span>
    },
    {
      key: 'numero_doc',
      dataIndex: 'numero_doc',
      title: 'Número Documento',
      render: (numero_doc) => <span className='nowrap'>{numero_doc}</span>
    },
    {
      key: 'correo',
      dataIndex: 'correo',
      title: 'Correo Usuario',
      render: (correo) => <span style={{ minWidth: 200 }}>{correo}</span>
    },
    {
      key: 'celular',
      dataIndex: 'celular',
      title: 'Celular',
      render: (celular) => (
        <span className='d-flex align-items-baseline nowrap' style={{ color: '#336cfb' }}>
          <span className='icofont icofont-ui-cell-phone mr-1' style={{ fontSize: 16 }} />
          {celular}
        </span>
      )
    },
    {
      key: 'direccion',
      dataIndex: 'direccion',
      title: 'Dirección',
      render: (direccion) => <span className='nowrap'>{direccion}</span>
    }
  ];

  const pagination = clientesAll.length <= 10 ? false : {};

  return (
    <>
      <Card style={{ background: 'rgba(251, 251, 251)' }} className='animated with-shadow'>
        <div style={{ display: 'flex', marginBottom: 20 }}>
          <Button
            type='primary'
            icon={<UserAddOutlined />}
            onClick={handleCrearCliente}
            style={{ backgroundColor: '#28a745', borderColor: '#28a745', marginRight: 8 }}
          >
            Crear Cliente
          </Button>
          <Input
            placeholder='Buscar clientes...'
            value={searchText}
            onChange={handleSearch}
            style={{ width: 300 }}
          />
        </div>
        <Table
          pagination={pagination}
          className='accent-header'
          rowKey='id'
          dataSource={filteredData}
          columns={columns}
        />
      </Card>
    </>
  );
};

export default ClientesTable;
