import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Avatar, Dropdown } from 'antd';
import { useAuth } from '../../../context/AuthContext';

interface AccountItem {
  text: string;
  icon?: string;
  route?: string; 
  action?: () => void; 
}

const SettingsDropdown = () => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();

  const handleInicioClick = () => {
    navigate('/vertical/ingresos');
  };

  const accountItems: AccountItem[] = [
    { text: `${user?.responsable}`, icon: 'icofont-ui-user', route: '/vertical/edit-account' },
    { text: `Tipo Usuario: ${user?.rol}`, icon: 'icofont-ui-settings' },
    { text: 'Cerrar Sesión', icon: 'icofont-logout', action: logout }
  ];

  const accountMenu = () => (
    <ul className='actions-menu' style={{ minWidth: '180px' }}>
      {accountItems.map((item, index) => (
        <li key={index} className='action-item' onClick={item.action}>
          {item.route ? (
            <NavLink to={item.route} className='d-flex w-100'>
              <span className={`icon mr-3 ${item.icon}`} />
              <span className='text'>{item.text}</span>
            </NavLink>
          ) : (
            <a className='d-flex w-100' onClick={e => { e.preventDefault(); item.action && item.action(); }}>
              <span className={`icon mr-3 ${item.icon}`} />
              <span className='text'>{item.text}</span>
            </a>
          )}
        </li>
      ))}
    </ul>
  );

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
       <div className='item' onClick={handleInicioClick} style={{ cursor: 'pointer' }}>
       <Avatar
         size={40}
         className='mr-1'
         src={`${window.location.origin}/content/inicio.webp`}
       />
     </div>
      <Dropdown overlay={accountMenu} trigger={['click']} placement='bottomRight'>
        <div className='item' style={{ cursor: 'pointer' }}>
          <Avatar
            size={40}
            className='mr-1'
            src={`${window.location.origin}/content/perfil1.jpg`}
          />
          <span className='icofont-simple-down' />
        </div>
      </Dropdown>
     
    </div>
  );
};

export default SettingsDropdown;

