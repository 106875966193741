import { useState, useEffect } from 'react';
import { API_BASE_URL } from '../config/apiConfig';

export const useAllClientes = () => {
  const [clientesAll, setClientesAll] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchClientes = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${API_BASE_URL}/clientes`); 
        if (!response.ok) {
          throw new Error(`API call failed with status: ${response.status}`);
        }
        const data = await response.json();
        setClientesAll(data); 
      } catch (error) {
        console.error('Error obteniendo clientes:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchClientes();
  }, []);

  const addCliente = (newCliente) => {
    setClientesAll(prev => [...prev, newCliente]);
  };

  const updateCliente = (updatedCliente) => { 
    setClientesAll(prev => prev.map(cliente => cliente.id === updatedCliente.id ? { ...cliente, ...updatedCliente } : cliente));
  };

  return { clientesAll, loading, addCliente, updateCliente }; 
};