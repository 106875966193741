import { useState, useEffect } from 'react';
import { API_BASE_URL } from '../config/apiConfig';

interface Ingreso {
  ingreso_id: number;
  sucursal: string;
  servicios: string;
  valor_total: number;
  fecha_creacion: string;
}

const useObtenerIngresosByPlaca = (placa: string) => {
  const [ingresos, setIngresos] = useState<Ingreso[] | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchIngresos = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await fetch(`${API_BASE_URL}/ingresoinformes?case=obtenerIngresosByPlaca&placa=${placa}`); 
        if (!response.ok) {
          throw new Error('Error al obtener los ingresos');
        }
        const data = await response.json();
        setIngresos(data.ingresos);
      } catch (err: any) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (placa) {
      fetchIngresos();
    }
  }, [placa]);

  return { ingresos, loading, error };
};

export default useObtenerIngresosByPlaca;
