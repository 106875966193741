import { ClearOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Card, Form, Input, Select, Table } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { Circle, Image as KonvaImage, Layer, Stage } from "react-konva";
import useImage from "use-image";
import { IMAGE_SERVER_URL } from "../../../config/apiConfig";
import { useClienteVehiculoByPlaca } from "../../../hooks/useClienteVehiculoByPlaca";
import { handleSaveImage } from "./GuardarImagenVehiculo";

const { Option } = Select;
const { TextArea } = Input;

const ProcesoVehiculoPage = ({ placa, form, setVehiculoObservacion, stageRef }) => {
  const { cliente } = useClienteVehiculoByPlaca(placa);
  const [selectedVehicle, setSelectedVehicle] = useState('3');
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [image] = useImage(imageSrc, 'anonymous');
  const [points, setPoints] = useState([]);
  const touchPoint = useRef({ x: null, y: null });

  // Elimina la inicialización de stageRef aquí
  // const stageRef = useRef(null); // No más necesario

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [current, setCurrent] = useState(1);
  const [textAreaValue, setTextAreaValue] = useState('');

  const handleFormChange = (changedValues) => {
    const [key, value] = Object.entries(changedValues)[0];
    if (key === 'observaciones') {
      setVehiculoObservacion(value); 
    }
    form.setFieldsValue({ [key]: value });
  };

  const handleMouseDown = (e) => {
    const stage = e.target.getStage();
    const pointerPosition = stage.getPointerPosition();
    if (pointerPosition) {
      setPoints([...points, { x: pointerPosition.x, y: pointerPosition.y }]);
    }
  };

  const handleTouchStart = (e) => {
    const touch = e.evt.touches[0];
    touchPoint.current = { x: touch.clientX, y: touch.clientY };
  };

  const handleTouchEnd = (e) => {
    const touch = e.evt.changedTouches[0];
    const moveX = Math.abs(touch.clientX - touchPoint.current.x);
    const moveY = Math.abs(touch.clientY - touchPoint.current.y);

    if (moveX < 10 && moveY < 10) {
      const stage = e.target.getStage();
      const pointerPosition = stage.getPointerPosition();
      if (pointerPosition) {
        setPoints([...points, { x: pointerPosition.x, y: pointerPosition.y }]);
      }
    }
  };

  const handleChange = (value) => {
    setSelectedVehicle(value);
    setPoints([]);
  };

  const handleDelete = () => {
    setPoints([]);
  };

  const handleClearText = () => {
    form.resetFields();
  };

  const handleAddDescription = (description) => {
    const currentText = form.getFieldValue('observaciones');
    const isDuplicatedDescription = currentText ? currentText.includes(description) : false;
    if (!isDuplicatedDescription)
      form.setFieldsValue({ observaciones: currentText ? `${currentText} - ${description}` : description });
  };

  const columns = [
    {
      key: 'actions',
      title: 'Acciones',
      render: (articulo) => (
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100px' }}>
          <Button shape='circle' icon={<PlusOutlined />} style={{ color: 'greenyellow' }} onClick={() => handleAddDescription(articulo.description)} />
        </div>
      )
    },
    {
      key: 'description',
      dataIndex: 'description',
      title: 'Descripción'
    }
  ];

  useEffect(() => {
    fetch('/data/articulos-vehiculo.json')
      .then((response) => response.json())
      .then((data) => {
        setData(data);
        setLoading(false);
      })
      .catch((error) => console.error('Error loading JSON:', error));
  }, []);
 
  useEffect(() => {
    const loadImages = async () => {
      if (selectedVehicle) {
        if (selectedVehicle === '3' && cliente && cliente.imagen_defecto) {
          const imagePath = `${IMAGE_SERVER_URL}/images/${cliente.imagen_defecto}`;
          try {
            const img = new Image();
            img.crossOrigin = 'anonymous';
            img.src = imagePath;
            await img.decode();
            setImageSrc(imagePath);
          } catch (error) {
            console.error('Error obteniendo la imagen histórica:', error);
            setImageSrc(null);
          }
        } else {
          setImageSrc(
            selectedVehicle === '1'
              ? '/content/vehiculo.png'
              : selectedVehicle === '2'
              ? '/content/moto.png'
              : null
          );
        }
      }
    };

    loadImages();
  }, [cliente, selectedVehicle]);

  return (
    <div>
      <div className='row'>
        <div className='col-md-2 col-sm-12 col-12'>
          <Select
            style={{ width: '200px', marginBottom: '15px', marginRight: '10px', alignContent: 'center' }}
            placeholder='Seleccione un tipo de vehículo'
            onChange={handleChange}
            value={selectedVehicle}
          >
            <Option value='1'>Vehículo</Option>
            <Option value='2'>Moto</Option>
            <Option value='3'>Histórico</Option>
          </Select>
          <Button
            type='primary'
            icon={<ClearOutlined />}
            onClick={handleDelete}
            style={{ width: '30px', height: '30px', alignContent: 'center' }}
          ></Button>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-3 col-sm-12 col-12'>
          <Card
            style={{
              height: '540px',
              border: '1px solid royalblue',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
            className='animated with-shadow'
          >
            <Stage
              width={300}
              height={510}
              ref={stageRef}
              onMouseDown={handleMouseDown}
              onTouchStart={handleTouchStart}
              onTouchEnd={handleTouchEnd}
            >
              <Layer>
                {image && <KonvaImage image={image} />}
                {points.map((point, i) => (
                  <Circle key={i} x={point.x} y={point.y} radius={5} fill='blue' />
                ))}
              </Layer>
            </Stage>
            <canvas id='canvasId' width='300' height='510' style={{ display: 'none' }}></canvas>
          </Card>
        </div>
        <div className='col-md-9 col-sm-12 col-12'>
          <Card
            style={{
              height: '540px',
              background: 'rgba(251, 251, 251)',
              border: '1px solid royalblue'
            }}
            className='animated with-shadow'
          >
            <Button
              type='primary'
              icon={<ClearOutlined />}
              onClick={handleClearText}
              style={{
                width: '30px',
                height: '30px',
                alignContent: 'center',
                marginBottom: '10px'
              }}
            ></Button>
            <Table
              columns={columns}
              dataSource={data}
              loading={loading}
              className='accent-header'
              pagination={{ pageSize: 5, current: current, onChange: (page) => setCurrent(page) }}
              style={{ marginBottom: '10px' }}
            />

            <Form form={form} onValuesChange={handleFormChange}>
              <Form.Item name='observaciones'>
                <TextArea
                  value={textAreaValue}
                  rows={4}
                  placeholder='Descripción de artículos y Observaciones'
                  onChange={(e) => setTextAreaValue(e.target.value)}
                />
              </Form.Item>
            </Form>
          </Card>
        </div>
      </div>
      {/* <Button type='primary' onClick={() => handleSaveImage(stageRef, placa)} style={{ marginTop: 20 }}>
        Guardar Imagen
      </Button> */}
    </div>
  );
};

export default ProcesoVehiculoPage;
