import { useState, useEffect } from 'react';
import { API_BASE_URL } from '../config/apiConfig';

export const useSucursales = () => {
  const [sucursales, setSucursales] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSucursales = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${API_BASE_URL}/controles?type=sucursal`);
        if (!response.ok) {
          throw new Error(`API call failed with status: ${response.status}`);
        }
        const data = await response.json();
        setSucursales(data);
      } catch (error) {
        console.error('Error fetching sucursales:', error); 
      } finally {
        setLoading(false);
      }
    };

    fetchSucursales();
  }, []);
    console.log(sucursales);
    console.log(loading);     
  return { sucursales, loading };
};