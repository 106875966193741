import React, { useRef } from 'react';
import { useParams, useNavigate, Navigate } from 'react-router-dom';
import { Button, Card } from 'antd';
import { useGetClienteById } from '../../../hooks/useGetClienteById';
import { usePageData } from '../../../hooks/usePage';
import { DoubleLeftOutlined, UserAddOutlined, CloseCircleFilled } from '@ant-design/icons';
import ProfileForm, { ProfileFormMethods } from './profileForm';
import { useUser } from '../../../hooks/useUser';

const PerfilClientePage = () => {
  const { Id } = useParams();
  const navigate = useNavigate();
  const { cliente, loading, error } = useGetClienteById(Id);
  const profileFormRef = useRef<ProfileFormMethods>(null);
  const { isAuthenticated, role } = useUser();

  usePageData({
    title: 'Perfil del Cliente',
    fulFilled: true
  }); 

  const allowedRoles = ['admin'];
  if (!isAuthenticated || !allowedRoles.includes(role)) {
    return <Navigate to="/public/page-404" replace />;
  }

  const handleSave = () => {
    if (profileFormRef.current) {
      profileFormRef.current.submitForm();

      setTimeout(() => {
        navigate(-1); 
      }, 500); 
    }
  };

  

  if (loading) return <div>Cargando datos del cliente...</div>;
  if (error) return <div>Error: {error.message}</div>;
  if (!cliente && Id) return <div>No se encontró el cliente</div>;

  return (
    <>
     <Card style={{ background: 'rgba(251, 251, 251)' }}
            className='animated with-shadow'>
      <div className='header mb-3'>
        <Button type='primary' ghost icon={<DoubleLeftOutlined />} onClick={() => navigate(-1)}>
          Regresar
        </Button>
        <h6 className='mt-6 mb-3'>INFORMACIÓN</h6>
      </div>
      <div className='info stack'>
        <ProfileForm ref={profileFormRef} /> 
        <div>
          <Button icon={<CloseCircleFilled />} style={{ marginRight: '10px', marginBottom: '20px' }} onClick={() => navigate(-1)}>
            Cancelar
          </Button>
          <Button 
            icon={<UserAddOutlined />} 
            type='primary'
            onClick={handleSave}
          >
            Guardar
          </Button>
        </div>
      </div>
      <Card title='Facturación...' className='mb-0' />
      </Card>
    </>
  );
};

export default PerfilClientePage;