import React, { useState } from 'react';
import { Button, Form, Input } from 'antd';
import { LoginOutlined } from '@ant-design/icons/lib';
import { useForm } from 'antd/es/form/Form';
import PublicLayout from '../../layout/public/Public';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';


const SignIn = () => {
  const [form] = useForm();
  const navigate = useNavigate();
  const { login } = useAuth(); 
  const [error, setError] = useState("");
  
  const handleLogin = async () => {
    try {
      const values = await form.validateFields();
      await login(values.login, values.password); 
      navigate('/vertical/ingresos', { replace: true });
    } catch (error) {
      console.error('Error during login:', error);
      setError("Error de autenticación. Por favor, verifica tus credenciales.");
    }
  };

  return (
    <PublicLayout bgImg={`${window.origin}/content/login4.webp`}>
      <h4 className='mt-0 mb-1'>Iniciar Sesión</h4>
      <Form form={form} layout='vertical' className='mb-4'>
        <Form.Item name='login' rules={[{ required: true, message: 'Ingrese el Correo' }]}>
          <Input placeholder='Correo Electrónico' />
        </Form.Item>
        <Form.Item name='password' rules={[{ required: true, message: 'Ingrese la Clave' }]}>
          <Input placeholder='Clave' type='password' />
        </Form.Item>
        <Button type='primary' onClick={handleLogin} htmlType='button' icon={<LoginOutlined style={{ fontSize: '1.5rem' }} />}>
          Ingresar
        </Button>
      </Form>
    </PublicLayout>
  );
};

export default SignIn;