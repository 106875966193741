import { useState, useEffect, useCallback } from 'react';

const useCheckClienteByIdentificacion = (identificacion: string) => {
  const [clientes, setClientes] = useState<{ id_cliente: number, nombre_cliente: string, identificacion: string }[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const resetState = useCallback(() => {
    setClientes([]);
    setLoading(false);
    setError(null);
  }, []);

  useEffect(() => {
    if (!identificacion) {
      resetState();
    }
  }, [identificacion, resetState]);

  const fetchCliente = async () => {
    resetState(); 
    setLoading(true);

    try {
      console.log('Fetching cliente for identificacion:', identificacion);
      const response = await fetch(`https://app.j4pro.com/jServerj4ErpPro/api/token/consultarClienteIdentificacion/${identificacion}`, {
        method: 'GET',
        headers: {
          'X-Auth-Token-empresa': '4411',
          'X-Auth-Token-api': '4411-202002620008ed7rv1194-7bfe-rc8e-b977-a17e04665f26e', 
          'Content-Type': 'application/json',
          'X-gtm': 'GMT-0500'
        }
      });

      if (!response.ok) {
        throw new Error('la red no responde');
      }

      const data = await response.json();
      console.log('Response data:', data);

      if (data.length > 0) {
        const ids = data.map((cliente: any) => ({ id_cliente: cliente.id_cliente, nombre_cliente: cliente.nombre_cliente, identificacion: cliente.identificacion }));
        setClientes(ids);
      } else {
        throw new Error('Cliente no encontrado');
      }
    } catch (error: unknown) {
      console.error('Error obteniendo cliente:', error);
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError('Error desconocido');
      }
    } finally {
      setLoading(false);
    }
  };

  return { clientes, loading, error, fetchCliente, setError, resetState };
};

export default useCheckClienteByIdentificacion;


