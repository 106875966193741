import React from 'react';
import { Navigate } from 'react-router-dom';
import { useUser } from '../../../hooks/useUser';
import { useAllClientes } from '../../../hooks/useAllClientes';
import ClientesTable from './clientesTable';
import { IClienteAll } from '../../../interfaces/clientes';
import { usePageData } from '../../../hooks/usePage';
import { Spin } from 'antd';

const ClientesPage: React.FC = () => {
  usePageData({ title: 'Clientes', fulFilled: true });
  const { loading, clientesAll } = useAllClientes();
  const { isAuthenticated, role } = useUser();

  const allowedRoles = ['admin'];

  if (!isAuthenticated || !allowedRoles.includes(role)) {
    return <Navigate to='/public/page-404' replace />;
  }

  if (loading ) {
    return (
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh' 
      }}>
        <Spin size='large' tip='Cargando...' />
      </div>
    );
  }
  const handleEditarCliente = (cliente: IClienteAll) => {
    console.log('Editar cliente', cliente);
  };

  return (
    <>
      <ClientesTable clientesAll={clientesAll} onEditarCliente={handleEditarCliente} />
    </>
  );
};

export default ClientesPage;
