import { useEffect, useState } from 'react';
import { API_BASE_URL } from '../config/apiConfig';

export function useGetProductoByIdCat(id, id_categoria) {
    const [producto, setProducto] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!id || !id_categoria) {
            setLoading(false);
            return;
        }

        const fetchProducto = async () => {
            setLoading(true); // Añadir aquí para manejar correctamente el estado de carga
            try {
                const response = await fetch(`${API_BASE_URL}/producto`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ action: 'obtenerproductobyidcat', id, id_categoria })
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const data = await response.json();
                if (Array.isArray(data.producto)) {
                    setProducto(data.producto);
                } else {
                    setProducto([]);
                }
            } catch (error) {
                setError(`Error al obtener el producto: ${error}`);
                setProducto([]);
            } finally {
                setLoading(false);
            }
        };

        fetchProducto();
    }, [id, id_categoria]);

    return { producto, loading, error };
}