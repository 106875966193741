import { useState, useEffect } from 'react';
import { API_BASE_URL } from '../config/apiConfig';

function useObtenerIngresos(id: string | null, fecha_inicio: string, fecha_fin: string, estados: string[]) {
  const [ingresos, setIngresos] = useState(null);
  const [cargando, setCargando] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    if (!fecha_inicio || !fecha_fin || !estados || estados.length === 0) {
      setError(new Error('Faltan parámetros requeridos para la consulta'));
      return;
    }

    async function fetchIngresos() {
      setCargando(true);
      setError(null);

      try {
        const estadosQuery = estados.join(',');
        let url = `${API_BASE_URL}/ingresos?fecha_inicio=${encodeURIComponent(
          fecha_inicio
        )}&fecha_fin=${encodeURIComponent(fecha_fin)}&estado=${encodeURIComponent(estadosQuery)}`;

        if (id !== undefined && id !== null) {
          url += `&id=${id}`;
        }

        const respuesta = await fetch(url);

        if (!respuesta.ok) {
          const errorText = await respuesta.text();
          throw new Error(errorText || 'Error en la solicitud');
        }

        const datos = await respuesta.json();
        if (datos.ingresos) {
          setIngresos(datos.ingresos);
        } else {
          throw new Error(datos.message || 'Error al obtener los ingresos');
        }
      } catch (error) {
        if (error instanceof Error) {
          setError(error);
        } else {
          setError(new Error('Error desconocido'));
        }
      } finally {
        setCargando(false);
      }
    }

    fetchIngresos();
  }, [id, fecha_inicio, fecha_fin, estados]);

  return { ingresos, cargando, error };
}

export default useObtenerIngresos;



