import React, { useEffect, useImperativeHandle } from 'react';
import { Form, Input, Select } from 'antd';
import { useParams } from 'react-router-dom';
import { useGetClienteById } from '../../../hooks/useGetClienteById';
import { useLocationData } from '../../../hooks/useLocationData';
import { useInsertCliente } from '../../../hooks/useInsertarCliente';
import { useActualizarCliente } from '../../../hooks/useActualizarCliente';
import { useAlert } from '../../../context/AlertContext';

const { Item: FormItem } = Form;
const { Option } = Select;
export interface ProfileFormMethods {
  submitForm: () => void;
}
const ProfileForm = React.forwardRef<ProfileFormMethods, {}>((props, ref) => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const { cliente, loading, error } = useGetClienteById(id);
  const { paises, departamentos, municipios, loadDepartamentos, loadMunicipios } =
    useLocationData();
  const { insertCliente } = useInsertCliente();
  const { updateCliente } = useActualizarCliente();
  const alertContext = useAlert();

  useImperativeHandle(ref, () => ({
    submitForm: () => form.submit()
  }));

  useEffect(() => {
    if (cliente) {
      form.setFieldsValue(cliente);
      loadDepartamentos(cliente.id_pais);
      loadMunicipios(cliente.id_departamento);
    }
  }, [cliente, form, loadDepartamentos, loadMunicipios]);

  const handleSubmit = async (values) => {
    const result = id ? await updateCliente({ ...values, id }) : await insertCliente(values);
    if (result && result.success) {
      //console.log(`${id ? 'Cliente actualizado' : 'Cliente insertado'} con éxito`);
      alertContext.triggerAlert({
        type: 'error',
        message: ` Error ${id ? 'Actualizando' : 'Insertando'} Cliente`,
        description: 'Módulo Clientes'
      });
    } else {
      //console.error(`Error ${id ? 'actualizando' : 'insertando'} cliente`);
      alertContext.triggerAlert({
        type: 'success',
        message: `${id ? 'Cliente Actualizado' : 'Cliente Insertado'} con éxito`,
        description: 'Módulo Clientes'
      });
    }
  };

  const handlePaisChange = (value) => {
    form.setFieldsValue({ id_departamento: null, id_municipio: null });
    loadDepartamentos(value);
  };

  const handleDepartamentoChange = (value) => {
    form.setFieldsValue({ id_municipio: null });
    loadMunicipios(value);
  };

  const handleMunicipioChange = (value) => {};

  if (loading) return <p>Cargando...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <Form
      form={form}
      layout='vertical'
      onFinish={handleSubmit}
      initialValues={
        cliente || {
          id_pais: 46
        }
      }
    >
      <div className='row'>
        <div className='col-md-2 col-sm-12'>
          <FormItem name='id_cliente_erp' label='Id ERP'>
            <Input placeholder='Id del ERP' />
          </FormItem>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-3 col-sm-12'>
          <FormItem name='nombres' label='Nombres'>
            <Input placeholder='Nombres del Cliente' />
          </FormItem>
        </div>
        <div className='col-md-2 col-sm-12'>
          <FormItem
            name='tipo_doc'
            label='Tipo Documento'
            initialValue={cliente?.tipo_doc}
            rules={[{ required: true, message: 'Seleccione un tipo de documento' }]}
          >
            <Select placeholder='Tipo Documento'>
              <Option value='CC'>CC</Option>
              <Option value='NIT'>NIT</Option>
            </Select>
          </FormItem>
        </div>

        <div className='col-md-3 col-sm-12'>
          <FormItem name='numero_doc' label='Identificación'>
            <Input placeholder='Identificación' />
          </FormItem>
        </div>
        <div className='col-md-2 col-sm-12'>
          <FormItem name='tipo_persona' label='Tipo Persona' initialValue={cliente?.tipo_persona}>
            <Select placeholder='Tipo Persona'>
              <Option value='natural'>Natural</Option>
              <Option value='juridica'>Juridica</Option>
            </Select>
          </FormItem>
        </div>
        <div className='col-md-2 col-sm-12'>
          <FormItem name='regimen' label='Régimen' initialValue={cliente?.regimen}>
            <Select placeholder='Régimen'>
              <Option value='Ninguno'>Ninguno</Option>
              <Option value='Regimen Simplificado'>Regimen Simplificado</Option>
              <Option value='Regimen Comun'>Regimen Comun</Option>
            </Select>
          </FormItem>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-4 col-sm-12'>
          <FormItem
            name='correo'
            label='Correo Electrónico'
            rules={[
              {
                type: 'email',
                message: 'El correo electrónico no es válido'
              },
              {
                //required: true,
                //message: 'El correo electrónico es obligatorio',
              }
            ]}
          >
            <Input placeholder='Correo' />
          </FormItem>
        </div>
        <div className='col-md-4 col-sm-12'>
          <FormItem name='celular' label='Teléfono'>
            <Input placeholder='Teléfono' />
          </FormItem>
        </div>

        <div className='col-md-4 col-sm-12'>
          <FormItem name='direccion' label='Dirección'>
            <Input placeholder='Dirección' />
          </FormItem>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-4 col-sm-12'>
          <FormItem
            name='id_pais'
            label='País'
            initialValue={cliente?.id_pais}
            rules={[{ required: true, message: 'Seleccione un país' }]}
          >
            <Select placeholder='Seleccione un país' onChange={handlePaisChange}>
              {paises.map((pais) => (
                <Option key={pais.id_pais} value={pais.id_pais}>
                  {pais.pais}
                </Option>
              ))}
            </Select>
          </FormItem>
        </div>
        <div className='col-md-4 col-sm-12'>
          <FormItem
            name='id_departamento'
            label='Departamento'
            rules={[{ required: true, message: 'Seleccione un departamento' }]}
          >
            <Select
              placeholder='Seleccione un departamento'
              onChange={handleDepartamentoChange}
              allowClear
            >
              {departamentos.map((depto) => (
                <Option key={depto.id_departamento} value={depto.id_departamento}>
                  {depto.departamento}
                </Option>
              ))}
            </Select>
          </FormItem>
        </div>
        <div className='col-md-4 col-sm-12'>
          <FormItem
            name='id_municipio'
            label='Municipio'
            rules={[{ required: true, message: 'Seleccione un municipio' }]}
          >
            <Select
              placeholder='Seleccione un municipio'
              onChange={handleMunicipioChange}
              allowClear
            >
              {municipios.map((muni) => (
                <Option key={muni.id_municipio} value={muni.id_municipio}>
                  {muni.municipio}
                </Option>
              ))}
            </Select>
          </FormItem>
        </div>
      </div>
    </Form>
  );
});

export default ProfileForm;
