import { useEffect, useState } from 'react';
import { API_BASE_URL } from '../config/apiConfig';

interface Ingreso {
  id: number;
  estado: string;
  placa: string;
}

export function useValidarIngreso(placa: string) {
  const [data, setData] = useState<Ingreso | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!placa) {
      setData(null);
      setLoading(false);
      return;
    }

    const fetchIngreso = async () => {
      setLoading(true);
      try {
        console.log(`Validando ingreso para: ${placa}`);
        const response = await fetch(`${API_BASE_URL}/validaringreso?placa=${placa}`, { 
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const result = await response.json();
        console.log(`Resultado de validación de ingreso:`, result);
        if (result.ingreso) {
          setData(result.ingreso);
        } else {
          setData(null);
        }
      } catch (error: any) {
        console.error(`Error al validar el ingreso: ${error.message}`);
        setError(`Error al validar el ingreso: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchIngreso();
  }, [placa]);

  return { data, loading, error };
}