import React from 'react';
import { Button, Tag, Table, Card } from 'antd';
import { ColumnProps } from 'antd/es/table';
import { IUsuarioAll3 } from '../../interfaces/usuarios';

const UsersTable: React.FC<{ usuariosAll: IUsuarioAll3[], onEditUser: (user: IUsuarioAll3) => void }> = ({ usuariosAll, onEditUser }) => {
  const actions = (usuario: IUsuarioAll3) => (
    <div className='buttons-list nowrap'>
      <Button shape='circle' type='primary' onClick={() => onEditUser(usuario)}>
        <span className='icofont icofont-edit-alt' />
      </Button>
    </div>
  );
  
  const columns: ColumnProps<IUsuarioAll3>[] = [
    {
      key: 'actions',
      title: 'Acciones',
      render: actions
    },
    {
      key: 'id',
      dataIndex: 'id',
      title: 'ID',
      sorter: (a, b) => (a.id > b.id ? 1 : -1),
      render: (id) => <strong>{id}</strong>
      
    },
    {
      key: 'id_ERP',
      title: 'Id_ERP',
      dataIndex: 'empleado',
      sorter: (a, b) => (a.empleado > b.empleado ? 1 : -1),
      render: (id_ERP) => <strong>{id_ERP}</strong>
    },
    {
      key: 'responsable',
      dataIndex: 'responsable',
      title: 'Usuario',
      sorter: (a, b) => (a.responsable > b.responsable ? 1 : -1),
      render: (responsable) => <strong>{responsable}</strong>
    },
    {
      key: 'TipoUsuario',
      dataIndex: 'rol',
      title: 'Tipo Usuario',
      sorter: (a, b) => (a.tipoUsuario > b.tipoUsuario ? 1 : -1),
      render: (tipoUsuario) => (
        <span className='nowrap' style={{ color: '#a5a5a5' }}>
          {tipoUsuario}
        </span>
      )
    },
    {
      key: 'sucursal',
      dataIndex: 'sucursal',
      title: 'Sucursal',
      render: (sucursal) => (
        <span className='nowrap' style={{ color: '#a5a5a5' }}>
          {sucursal}
        </span>
      )
    },
    {
      key: 'correo',
      dataIndex: 'correo',
      title: 'Correo Usuario',
      render: (address) => <span style={{ minWidth: 200, display: 'block' }}>{address}</span>
    },
    {
      key: 'celular',
      dataIndex: 'celular',
      title: 'Celular',
      render: (phone) => (
        <span className='d-flex align-baseline nowrap' style={{ color: '#336cfb' }}>
          <span className='icofont icofont-ui-cell-phone mr-1' style={{ fontSize: 16 }} /> {phone} </span>
      )
    },
 
    {
      key: 'estado',
      dataIndex: 'estado',
      title: 'Estado',
      render: (estado) => (
        <Tag style={{ borderRadius: 20 }} color={estado === 'Activo' ? '#b7ce63' : '#ff7f7f'}>
          {estado}
        </Tag>
      ),
      
    }
    
  ];

  const pagination = usuariosAll.length <= 10 ? false : {};

  return (
    <>
    <Card style={{ background: 'rgba(251, 251, 251)' }}
            className='animated with-shadow'>
      <Table
        pagination={pagination}
        className='accent-header'
        rowKey='id'
        dataSource={usuariosAll}
        columns={columns}
      />
      </Card>
    </>
  );
};

export default UsersTable;