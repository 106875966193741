import { useEffect, useState } from 'react';
import { API_BASE_URL } from '../config/apiConfig';

export function useGetProductoById(id) {
    const [producto, setProducto] = useState(null);
    const [loading, setLoading] = useState(true); 
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!id) {
            setLoading(false); 
            return;
        }

        const fetchProducto = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}/producto`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ action: 'obtenerproducto', id })
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const { producto } = await response.json();
                if (producto) {
                    setProducto(producto);
                } else {
                    throw new Error('Producto no encontrado');
                }
            } catch (error) {
                setError(`Error al obtener el producto: ${error}`);
            } finally {
                setLoading(false);
            }
        };

        fetchProducto();
    }, [id]);

    return { producto, loading, error };
}