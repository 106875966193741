import { useState, useEffect } from 'react';
import { API_BASE_URL } from '../config/apiConfig';

export const useAllProductos = () => {
  const [productosAll, setProductosAll] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProductos = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${API_BASE_URL}/producto`);
        if (!response.ok) {
          throw new Error(`API call failed with status: ${response.status}`);
        }
        const data = await response.json();
        setProductosAll(data);
      } catch (error) {
        console.error('Error obteniendo productos:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchProductos();
  }, []);

  const addProducto = (newProducto) => {
    setProductosAll((prev) => [...prev, newProducto]);
  };

  const updatedProducto = (updatedProducto) => {
    setProductosAll((prev) =>
      prev.map((producto) =>
        producto.id === updatedProducto.id ? { ...producto, ...updatedProducto } : producto
      )
    );
  };

  return { productosAll, loading, addProducto, updatedProducto };
};
