import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { IPageData } from "../interfaces/page";
import { setPageData, updatePageDada } from "../redux/page-data/actions";

export function usePageData(pageData: IPageData) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPageData({ ...pageData, loaded: true }));
  }, [pageData, dispatch]);
}

export function useFetchPageData<T>(
  url: string,
  initialState: T = null,
  callback?: (T) => any
): [T, (data: T) => void] {
  const [data, setData] = useState<T>(initialState);
  const dispatch = useDispatch();

  const getData = useCallback(async () => {
    const result = await axios.get(url);
    return result.data;
  }, [url]);

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await getData();
        console.log('Data retrieved:', data);
        if (callback) {
          callback(data);
        }

        setTimeout(() => dispatch(updatePageDada({ fulFilled: true, loaded: true })), 300);

        setData(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }

    fetchData();
  }, [url, dispatch, getData, callback]);

  return [data, setData];
}
