import React from 'react';
import { Navigate } from 'react-router-dom';
import { useUser } from '../../hooks/useUser';
import { useAllVehiculos } from '../../hooks/useAllVehiculos';
import VehiculosTable from './vehiculosTable';
import { usePageData } from '../../hooks/usePage';
import { Spin } from 'antd';

const VehiculosPage: React.FC = () => {
  usePageData({ title: 'Módulo Vehículos', fulFilled: true });
  const { loading, vehiculosAll } = useAllVehiculos();
  const { isAuthenticated, role } = useUser();

  const allowedRoles = ['admin', 'user'];

  if (!isAuthenticated || !allowedRoles.includes(role)) {
    return <Navigate to='/public/page-404' replace />;
  }

  if (loading ) {
    return (
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh' 
      }}>
        <Spin size='large' tip='Cargando...' />
      </div>
    );
  }

  return (
    <>
      <VehiculosTable vehiculosAll={vehiculosAll} />
    </>
  );
};

export default VehiculosPage;





