import "./Vertical.scss";

import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import LogoSvg from "../../assets/img/aprisa_logo.jpg";
import { useAuth } from "../../context/AuthContext";
import { IAppState } from "../../interfaces/app-state";
import { toggleSidebar } from "../../redux/settings/actions";
import BaseLayout from "../base/BaseLayout";
import Actions from "../components/actions/Actions";
import Logo from "../components/logo/Logo";
import Menu from "../components/menu/Menu";
import Navbar from "../components/navbar/Navbar";
import NavLoader from "../components/navbar/NavLoader";
import Search from "../components/textempresa/TextEmpresa";
import ClienteRapido from "../../pages/contactos/clientes/clienteRapido";

type Props = {
  children: any;
};


const VerticalLayout = ({ children }: Props) => {
  const dispatch = useDispatch();
  const settings = useSelector((state: IAppState) => state.settings);
  const pageData = useSelector((state: IAppState) => state.pageData);
  const [menuData, setMenuData] = useState([]);
  const { user } = useAuth();
  
 /*  if (user && user.sucursalnombre) {
    console.log("Nombre de la Sucursal:", user.sucursalnombre);
  } else {
    console.log("Usuario no definido o sucursalNombre no disponible");
  } */
  
  useEffect(() => {
    async function fetchMenuData() {
      const result = await axios('/data/menu.json');
      setMenuData(result.data);
    }
    fetchMenuData().catch((err) => console.log('Server Error', err));
  }, []);

  const onSidebarToggle = () => dispatch(toggleSidebar());

  const addClienteToList = (nuevoCliente) => {
    // Lógica para añadir el cliente a la lista
  };

  const nav = (
    <Navbar
      boxed={settings.boxed}
      color={settings.topbarColor}
      background={settings.topbarBg}
      orientation='horizontal'
    >
      <button className='no-style navbar-toggle d-lg-none' onClick={onSidebarToggle}>
        <span />
        <span />
        {/*<span />*/}
      </button>
      <Search layout='vertical' />
      <Actions />
      <NavLoader loaded={pageData.loaded} type={'top-bar'} />
    </Navbar>
  );

  const sideNav = (
    <Navbar
      onClickOutside={onSidebarToggle}
      opened={settings.sidebarOpened}
      color={settings.sidebarColor}
      background={settings.sidebarBg}
      orientation='vertical'
    >
      <Logo src={LogoSvg} />
      <Menu
        onCloseSidebar={onSidebarToggle}
        opened={settings.sidebarOpened}
        orientation='vertical'
        data={menuData}
      />
       <ClienteRapido onAddCliente={addClienteToList} />

      <Menu className='assistant-menu' orientation='vertical'>
        <NavLink to='/vertical/edit-account'
          className={`link ${({ isActive }) => (isActive ? 'active' : 'inactive')}`}
          replace
        >
          <span className='link-icon icofont icofont-user-alt-3' />
          <span className='link-text'>{user?.responsable}</span>
        </NavLink>
        <NavLink to='#'
          className={`link ${({ isActive }) => (isActive ? 'active' : 'inactive')}`}
          replace
        >
          <span className='link-icon icofont icofont-justice' />
          <span className='link-text'>Tipo usuario :{user?.rol}</span>
        </NavLink>
        <NavLink to='#'
          className={`link ${({ isActive }) => (isActive ? 'active' : 'inactive')}`}
          replace
        >
          <span className='link-icon icofont icofont-justice' />
          <span className='link-text'>Sucursal :{user?.sucursalnombre}</span>
        </NavLink>
      </Menu>
      <NavLoader loaded={pageData.loaded} type={'nav-bar'}/>
    </Navbar>


  );
 

  return (
    <>
      <BaseLayout orientation='vertical' nav={nav} sideNav={sideNav}>
        {children}
      </BaseLayout>
    </>
  );
};

export default VerticalLayout;
