import React, { useRef } from 'react';
import { useParams, useNavigate, Navigate } from 'react-router-dom';
import { Button, Card, Spin } from 'antd';
import { useGetProductoById } from '../../hooks/useGetProductoById';
import { usePageData } from '../../hooks/usePage';
import { DoubleLeftOutlined, CloseCircleFilled, PlusSquareOutlined } from '@ant-design/icons'; 
import ProfileFormProducto, { ProfileFormMethods } from './profileFormProducto';
import { useUser } from '../../hooks/useUser';
import ListaPreciosTable from './listapreciostable';

const PerfilProductoPage = () => {
  const { id } = useParams();
  console.log('PerfilProductoPage - Id:', id);
  const navigate = useNavigate();
  const { producto, loading, error } = useGetProductoById(id);
  const profileFormRef = useRef<ProfileFormMethods>(null);
  const { isAuthenticated, role } = useUser();

  usePageData({
    title: 'Edición o Creación de Producto',
    fulFilled: true
  });

  const allowedRoles = ['admin'];
  if (!isAuthenticated || !allowedRoles.includes(role)) {
    return <Navigate to='/public/page-404' replace />;
  }

  const handleSave = () => {
    if (profileFormRef.current) {
      profileFormRef.current.submitForm();

      setTimeout(() => {
        navigate(-1);
      }, 500);
    }
  };

  if (loading) {
    return (
      <div className='centered-spinner'>
        <Spin size='large' tip='Cargando...' />
      </div>
    );
  }
  
  if (error) return <div>Error: {error.message}</div>;
  if (!producto && id) return <div>No se encontró el producto</div>;

  return (
    <>
    <Card style={{ background: 'rgba(251, 251, 251)' }}
            className='animated with-shadow'>
      <div className='header mb-3'>
        <Button type='primary' ghost icon={<DoubleLeftOutlined />} onClick={() => navigate(-1)}>
          Regresar
        </Button>
        <h6 className='mt-6 mb-3'>INFORMACIÓN DEL PRODUCTO</h6>
      </div>
      <div className='info stack'>
        <ProfileFormProducto ref={profileFormRef} />
        <div>
          <Button
            icon={<CloseCircleFilled />}
            style={{ marginRight: '10px', marginBottom: '20px' }}
            onClick={() => navigate(-1)}
          >
            Cancelar
          </Button>
          <Button icon={<PlusSquareOutlined />} type='primary' onClick={handleSave}>
            Guardar
          </Button>
        </div>
      </div>
      <Card title='Lista de Precios' className='mb-0'>
        <ListaPreciosTable idProducto={id} />
      </Card>
      </Card>
    </>
  );
};

export default PerfilProductoPage;
