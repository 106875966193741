import React from 'react';
import { Navigate } from 'react-router-dom';
import { useUser } from '../../hooks/useUser';
import { useAllProductos } from '../../hooks/useAllProductos';
import ProductosTable from './productosTable';

import { usePageData } from '../../hooks/usePage';
import { Spin } from 'antd';

const ProductosPage: React.FC = () => {
  usePageData({ title: 'Módulo Productos', fulFilled: true });
  const { loading, productosAll } = useAllProductos();
  const { isAuthenticated, role } = useUser();

  const allowedRoles = ['admin'];

  if (!isAuthenticated || !allowedRoles.includes(role)) {
    return <Navigate to='/public/page-404' replace />;
  }

  if (loading ) {
    return (
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh' 
      }}>
        <Spin size='large' tip='Cargando...' />
      </div>
    );
  }

  return (
    <>
      <ProductosTable productosAll={productosAll} />
    </>
  );
};

export default ProductosPage;
