import { useEffect, useState } from 'react';
import { API_BASE_URL } from '../config/apiConfig';

export function useListaPrecioById(idProducto) {
  const [listaprecio, setListaPrecio] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!idProducto) {
      setLoading(false);
      return;
    }

    const fetchListaPrecio = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/producto`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ action: 'obtenerlistaprecio', id: idProducto })
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const { listaprecio } = await response.json(); //Debo tener encuenta el arreglo para renderizar la petición del json
        if (Array.isArray(listaprecio) && listaprecio.length > 0) {
          setListaPrecio(listaprecio);
        } else {
          console.log('No se encontró lista de precio con el ID', idProducto);
          throw new Error('Lista de Precio no encontrada');
        }
      } catch (error) {
        setError(`Error al obtener la Lista de Precios: ${error}`);
      } finally {
        setLoading(false);
      }
    };

    fetchListaPrecio();
  }, [idProducto]);

  return { listaprecio, loading, error };
}
