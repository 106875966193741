import { useEffect, useState } from 'react';
import { API_BASE_URL } from '../config/apiConfig';

interface Cliente {
  placa: string;
  descripcion: string;
  nombres: string;
  tipo_doc: string;
  numero_doc: string;
  correo: string;
  celular: string;
  direccion: string;
  imagen_defecto: string | null;
  id: number;
}

export function useClienteVehiculoByPlaca(placa: string) {
  const [cliente, setCliente] = useState<Cliente | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!placa) {
      setCliente(null);
      setLoading(false);
      return;
    }

    const fetchCliente = async () => {
      setLoading(true);
      try {
        console.log(`Obteniendo datos del cliente para la placa: ${placa}`);
        const response = await fetch(`${API_BASE_URL}/vehiculoplaca?placa=${placa}`, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const result = await response.json();
        console.log(`Datos del cliente obtenidos:`, result);

        if (result && result.placa && result.placa.length > 0) {
          setCliente(result.placa[0]);
        } else {
          setCliente(null);
        }
      } catch (error: any) {
        console.error(`Error al obtener los datos del cliente: ${error.message}`);
        setError(`Error al obtener los datos del cliente: ${error.message}`);
        setCliente(null);
      } finally {
        setLoading(false); 
      }
    };

    fetchCliente();
  }, [placa]);

  return { cliente, loading, error };
}


