import { DeleteOutlined, LockOutlined, PlusOutlined, PlusSquareOutlined } from '@ant-design/icons';
import { Alert, Button, Form, Input, message, Switch, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetProductoByIdCat } from '../../../hooks/useGetProductoByIdCat';
import useUpsertServicioIngreso, {
  Cliente,
  Ingreso,
  Vehiculo
} from '../../../hooks/useUpsertServicioIngreso';
import { useUser } from '../../../hooks/useUser';

const roundToNearest = (value, nearest) => {
  return Math.round(value / nearest) * nearest;
};

interface ServicioIngresoPageProps {
  id_categoria: string;
  clienteData: Cliente;
  vehiculoData: Vehiculo;
}

const ServicioIngresoPage: React.FC<ServicioIngresoPageProps> = ({
  id_categoria,
  clienteData,
  vehiculoData
}) => {
  console.log('🚀 ~ file: ServicioIngreso.tsx:21 ~ vehiculoData:', vehiculoData);

  const [form] = Form.useForm();
  const { userId } = useUser();
  const { producto } = useGetProductoByIdCat(userId, id_categoria);
  const {
    upsertServicioIngreso,
    loading: upsertLoading,
    error: upsertError,
    success
  } = useUpsertServicioIngreso();
  const navigate = useNavigate();

  const [selectedProducts, setSelectedProducts] = useState([]);
  const [subtotal, setSubtotal] = useState(0);
  const [impuestos, setImpuestos] = useState(0);
  const [descuento, setDescuento] = useState(0);
  const [neto, setNeto] = useState(0);
  const [filterText, setFilterText] = useState('');
  const [descuentoEmpleado, setDescuentoEmpleado] = useState(false); 

  const [ingresoData, setIngresoData] = useState<Ingreso>({
    usuario: userId,
    estado: 'ingresado',
    clave: '',
    valor_total: 0,
    acepta_terminos: false,
    fecha_sms: new Date(),
    fecha_creacion: new Date(),
    envio_sms: false,
    productos: []
  });

  useEffect(() => {
    let newSubtotal = 0;
    let newImpuestos = 0;
    let newDescuento = 0;

    selectedProducts.forEach((producto) => {
      const discountPercentage = descuentoEmpleado ? 0.15 :
        selectedProducts.length >= 4 ? 0.1 : selectedProducts.length === 3 ? 0.05 : 0;
      const discountedValue = roundToNearest(producto.valor * (1 - discountPercentage), 1000);
      const impuestoCalculado = discountedValue - discountedValue / (1 + producto.impuesto / 100);
      const subtotalValue = discountedValue - impuestoCalculado;

      newSubtotal += subtotalValue;
      newImpuestos += impuestoCalculado;
      newDescuento += roundToNearest(producto.valor * discountPercentage, 1000);
    });

    setSubtotal(newSubtotal);
    setImpuestos(newImpuestos);
    setDescuento(newDescuento);
    setNeto(newSubtotal + newImpuestos);
  }, [selectedProducts, descuentoEmpleado]);

  const handleAddProduct = (producto) => {
    if (selectedProducts.some((p) => p.referencia === producto.referencia)) {
      return;
    }
    setSelectedProducts([...selectedProducts, producto]);
  };

  const handleRemoveProduct = (producto) => {
    setSelectedProducts(selectedProducts.filter((p) => p.referencia !== producto.referencia));
  };

  const handleSave = async () => {
    if (selectedProducts.length === 0) {
      message.error('Debe seleccionar al menos un producto.');
      return;
    }

    if (ingresoData.clave.length !== 4) {
      message.error('La clave debe tener 4 caracteres.');
      return;
    }

    const productos = selectedProducts.map((producto) => {
      const discountPercentage = descuentoEmpleado ? 0.15 :
        selectedProducts.length >= 4 ? 0.1 : selectedProducts.length === 3 ? 0.05 : 0;
      const discountedValue = roundToNearest(producto.valor * (1 - discountPercentage), 1000);
      const impuestoCalculado = discountedValue - discountedValue / (1 + producto.impuesto / 100);
      const subtotalValue = discountedValue - impuestoCalculado;
      return {
        producto_id: producto.id,
        precio_con_iva: subtotalValue,
        precio_antes_iva: impuestoCalculado,
        valor_descuento: roundToNearest(producto.valor * discountPercentage, 1000),
        neto: discountedValue,
        porcentaje_descuento: discountPercentage * 100
      };
    });

    const clienteDataCompleto = {
      ...clienteData,
      id_pais: 46,
      id_departamento: 3,
      id_municipio: 12
    };

    const historicoVehiculo = {
      imagen_defecto: `imagen_${vehiculoData.placa}.png`,
      observacion: vehiculoData.observacion
    };

    const adjustDateByHours = (date, hours) => {
      const newDate = new Date(date);
      newDate.setHours(newDate.getHours() - hours);
      return newDate.toISOString();
    };

    const ingreso = {
      ...ingresoData,
      productos,
      valor_total: neto,
      fecha_sms: adjustDateByHours(ingresoData.fecha_sms, 5),
      fecha_creacion: adjustDateByHours(ingresoData.fecha_creacion, 5)
    };

    console.log('Datos del cliente', clienteDataCompleto);
    console.log('Datos del vehículo', vehiculoData);
    console.log('Datos del historico vehículo', historicoVehiculo);
    console.log('Datos del ingreso', ingreso);

    const confirmed = window.confirm('¿Está seguro de guardar el Ingreso?');
    if (confirmed) {
      try {
        await upsertServicioIngreso(clienteDataCompleto, vehiculoData, historicoVehiculo, ingreso);
        message.success('Guardado Exitosamente');
        navigate(-1);
      } catch (error) {
        message.error('Error al guardar');
      }
    }
  };

  const handleLockClick = () => {
    setIngresoData({ ...ingresoData, clave: '1234' });
  };

  const filteredProducts = producto.filter(
    (prod) =>
      prod.referencia.toLowerCase().includes(filterText.toLowerCase()) ||
      prod.descrip_prod.toLowerCase().includes(filterText.toLowerCase())
  );

  const productColumns = [
    {
      key: 'actions',
      title: 'Acción',
      width: 50,
      render: (producto) => (
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100px' }}>
          <Button
            shape='circle'
            icon={<PlusOutlined />}
            style={{ color: 'greenyellow' }}
            onClick={() => handleAddProduct(producto)}
          />
        </div>
      )
    },
    {
      key: 'id',
      dataIndex: 'id',
      title: 'Id'
    },
    {
      key: 'referencia',
      dataIndex: 'referencia',
      title: 'Ref'
    },
    {
      key: 'descrip_prod',
      dataIndex: 'descrip_prod',
      title: 'Descripción'
    },
    {
      key: 'impuesto',
      dataIndex: 'impuesto',
      title: 'Iva'
    },
    {
      key: 'valor',
      dataIndex: 'valor',
      title: 'Valor',
      render: (valor) => (
        <div style={{ textAlign: 'right' }}>
          <span className='nowrap' style={{ color: '#336cfb' }}>
            {new Intl.NumberFormat('es-CO', { style: 'currency', currency: 'COP' }).format(valor)}
          </span>
        </div>
      )
    }
  ];

  const selectedColumns = [
    {
      key: 'actions',
      title: 'Acción',
      width: 50,
      render: (producto) => (
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100px' }}>
          <Button
            shape='circle'
            icon={<DeleteOutlined />}
            danger
            onClick={() => handleRemoveProduct(producto)}
          />
        </div>
      )
    },
    {
      key: 'id',
      dataIndex: 'id',
      title: 'Id'
    },
    {
      key: 'referencia',
      dataIndex: 'referencia',
      title: 'Ref'
    },
    {
      key: 'descrip_prod',
      dataIndex: 'descrip_prod',
      title: 'Descripción'
    },
    {
      key: 'impuesto',
      title: 'Iva',
      render: (producto) => {
        const discountPercentage = descuentoEmpleado ? 0.15 :
          selectedProducts.length >= 4 ? 0.1 : selectedProducts.length === 3 ? 0.05 : 0;
        const discountedValue = roundToNearest(producto.valor * (1 - discountPercentage), 1000);
        const impuestoCalculado = discountedValue - discountedValue / (1 + producto.impuesto / 100);
        return (
          <div style={{ textAlign: 'right' }}>
            <span className='nowrap' style={{ color: '#336cfb' }}>
              {new Intl.NumberFormat('es-CO', { style: 'currency', currency: 'COP' }).format(
                impuestoCalculado
              )}
            </span>
          </div>
        );
      }
    },
    {
      key: 'subtotal',
      title: 'Subtotal',
      render: (producto) => {
        const discountPercentage = descuentoEmpleado ? 0.15 :
          selectedProducts.length >= 4 ? 0.1 : selectedProducts.length === 3 ? 0.05 : 0;
        const discountedValue = roundToNearest(producto.valor * (1 - discountPercentage), 1000);
        const impuestoCalculado = discountedValue - discountedValue / (1 + producto.impuesto / 100);
        const subtotalValue = discountedValue - impuestoCalculado;
        return (
          <div style={{ textAlign: 'right' }}>
            <span className='nowrap' style={{ color: '#336cfb' }}>
              {new Intl.NumberFormat('es-CO', { style: 'currency', currency: 'COP' }).format(
                subtotalValue
              )}
            </span>
          </div>
        );
      }
    },
    {
      key: 'descuento',
      title: 'Descuento',
      render: (producto) => {
        const discountPercentage = descuentoEmpleado ? 0.15 :
          selectedProducts.length >= 4 ? 0.1 : selectedProducts.length === 3 ? 0.05 : 0;
        const discountValue = roundToNearest(producto.valor * discountPercentage, 1000);
        return (
          <div style={{ textAlign: 'right' }}>
            <span className='nowrap' style={{ color: '#336cfb' }}>
              {new Intl.NumberFormat('es-CO', { style: 'currency', currency: 'COP' }).format(
                discountValue
              )}
            </span>
          </div>
        );
      }
    },
    {
      key: 'valor',
      title: 'Valor Total',
      render: (producto) => {
        const discountPercentage = descuentoEmpleado ? 0.15 :
          selectedProducts.length >= 4 ? 0.1 : selectedProducts.length === 3 ? 0.05 : 0;
        const discountedValue = roundToNearest(producto.valor * (1 - discountPercentage), 1000);
        return (
          <div style={{ textAlign: 'right' }}>
            <span className='nowrap' style={{ color: '#336cfb' }}>
              {new Intl.NumberFormat('es-CO', { style: 'currency', currency: 'COP' }).format(
                discountedValue
              )}
            </span>
          </div>
        );
      }
    }
  ];

  return (
    <Form form={form} layout='vertical'>
      <div className='row'>
        <div className='col-md-2 col-sm-12'>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
            <Switch checked={descuentoEmpleado} onChange={(checked) => setDescuentoEmpleado(checked)} />
            <span style={{ marginLeft: '10px' }}>Descuento Empleado</span>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-2 col-sm-12'>
          <Input
            placeholder='Buscar Servicios'
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
            style={{ marginBottom: '20px' }}
          />
        </div>
      </div>

      <Table
        columns={productColumns}
        dataSource={filteredProducts}
        rowKey='id'
        pagination={{ pageSize: 5 }}
      />
      <Table
        columns={selectedColumns}
        dataSource={selectedProducts}
        rowKey='id'
        pagination={false}
      />
      <div style={{ marginTop: '20px' }}>
        <div className='row'>
          <div className='col-md-3 col-sm-12'>
            <Input
              addonBefore={<span className='custom-addon'>Subtotal:</span>}
              value={new Intl.NumberFormat('es-CO', { style: 'currency', currency: 'COP' }).format(
                subtotal
              )}
              readOnly
              style={{ marginBottom: '3px', width: '100%', textAlign: 'right', color: '#336cfb' }}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-md-3 col-sm-12'>
            <Input
              addonBefore={<span className='custom-addon'>Impuestos:</span>}
              value={new Intl.NumberFormat('es-CO', { style: 'currency', currency: 'COP' }).format(
                impuestos
              )}
              readOnly
              style={{ marginBottom: '2px', width: '100%', textAlign: 'right', color: '#336cfb' }}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-md-3 col-sm-12'>
            <Input
              addonBefore={<span className='custom-addon'>Descuento:</span>}
              value={new Intl.NumberFormat('es-CO', { style: 'currency', currency: 'COP' }).format(
                descuento
              )}
              readOnly
              style={{ marginBottom: '2px', width: '100%', textAlign: 'right', color: '#336cfb' }}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-md-3 col-sm-12'>
            <Input
              addonBefore={<span className='custom-addon'>Total:</span>}
              value={new Intl.NumberFormat('es-CO', { style: 'currency', currency: 'COP' }).format(
                neto
              )}
              readOnly
              style={{ marginBottom: '8px', width: '100%', textAlign: 'right', color: '#336cfb' }}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-md-3 col-sm-12'>
            <Input
              placeholder='Clave'
              type='password'
              value={ingresoData.clave}
              onChange={(e) => setIngresoData({ ...ingresoData, clave: e.target.value })}
              addonAfter={<LockOutlined onClick={handleLockClick} style={{ cursor: 'pointer' }} />}
              style={{ marginBottom: '10px' }}
            />
          </div>
          <div className='col-md-2 col-sm-12'>
            <Button
              icon={<PlusSquareOutlined />}
              type='primary'
              style={{ width: '145px' }}
              onClick={handleSave}
              disabled={upsertLoading}
            >
              {upsertLoading ? 'Guardando...' : 'Enviar'}
            </Button>
          </div>
        </div>
      </div>
      {upsertError && <Alert message={upsertError} type='error' />}
      {success && <Alert message='Guardado exitosamente' type='success' />}
    </Form>
  );
};

export default ServicioIngresoPage;

