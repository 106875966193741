import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate, Navigate } from 'react-router-dom';
import { Button, Spin, Form, Input, Select, Card, Modal, Table } from 'antd';
import { SearchOutlined, MailOutlined, PlusSquareOutlined, CloseCircleFilled, DoubleLeftOutlined } from '@ant-design/icons';
import { useClienteVehiculoByPlaca } from '../../hooks/useClienteVehiculoByPlaca';
import { usePageData } from '../../hooks/usePage';
import { useTipoVehiculo } from '../../hooks/useTipoVehiculo';
import { useAlert } from '../../context/AlertContext';
import { useUser } from '../../hooks/useUser';
import useUpsertVehiculoCliente, { Cliente, Vehiculo } from '../../hooks/useUpsertVehiculoCliente';
import { useGetClienteByNumeroDoc } from '../../hooks/useGetClienteByNumeroDoc';

const { Item: FormItem } = Form;
const { Option } = Select;

const PerfilVehiculoPage = () => {
  const { placa } = useParams<{ placa: string }>();
  const navigate = useNavigate();
  const { cliente, loading, error } = useClienteVehiculoByPlaca(placa);
  const { tipovehiculo } = useTipoVehiculo();
  const alertContext = useAlert();
  const [form] = Form.useForm();
  const { isAuthenticated, role } = useUser();
  const { upsertVehiculoCliente, loading: saving, success } = useUpsertVehiculoCliente();

  const [numeroDoc, setNumeroDoc] = useState('');
  const { data: clienteSuggestions, loading: loadingCliente, error: errorCliente } = useGetClienteByNumeroDoc(numeroDoc);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [isClienteGeneralDisabled, setIsClienteGeneralDisabled] = useState(false);

  usePageData({
    title: 'Edición o Creación de Vehículo',
    fulFilled: true
  });

  const allowedRoles = ['admin', 'user'];

  useEffect(() => {
    if (cliente) {
      form.setFieldsValue({
        placa: cliente.placa,
        tipo_vehiculo: cliente.id,
        nombre_cliente: cliente.nombres,
        tipo_doc: cliente.tipo_doc,
        numero_doc: cliente.numero_doc,
        correo: cliente.correo,
        celular: cliente.celular,
        direccion: cliente.direccion
      });
      setIsClienteGeneralDisabled(cliente.numero_doc === '7777777');
    }
  }, [cliente, form]);

  const handleSetDefaultEmail = useCallback(() => {
    form.setFieldsValue({
      correo: 'facturag4@gmail.com'
    });
  }, [form]);

  if (!isAuthenticated || !allowedRoles.includes(role)) {
    return <Navigate to='/public/page-404' replace />;
  }

  const handleSave = () => {
    form.submit();
  };

  const handleSubmit = async (values) => {
    const clienteData: Cliente = {
      nombres: values.nombre_cliente,
      tipo_doc: values.tipo_doc,
      numero_doc: values.numero_doc,
      tipo_persona: 'Natural',
      regimen: 'Ninguno',
      correo: values.correo,
      celular: values.celular,
      direccion: values.direccion,
      id_pais: 46,
      id_departamento: 3,
      id_municipio: 12
    };

    const vehiculoData: Vehiculo = {
      placa: values.placa.toUpperCase(),
      tipo_vehiculo: values.tipo_vehiculo
    };
    console.log(vehiculoData);

    await upsertVehiculoCliente(clienteData, vehiculoData);

    if (success) {
      alertContext.triggerAlert({
        type: 'error',
        message: 'Error en el Proceso',
        description: 'Módulo Vehículos'
      });
      setTimeout(() => {
        navigate(-1);
      }, 500);
    } else {
      alertContext.triggerAlert({
        type: 'success',
        message: 'Proceso realizado con éxito',
        description: 'Módulo Vehículos'
      });
      navigate(-1);
    }
  };

  const handlePlacaChange = (e) => {
    const { value } = e.target;
    form.setFieldsValue({ placa: value.toUpperCase() });
  };

  const handleNumeroDocChange = (e) => {
    const value = e.target.value;
    setNumeroDoc(value);
    form.setFieldsValue({ numero_doc: value });
  };

  const openModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  const handleSuggestionClick = (cliente) => {
    form.setFieldsValue({
      nombre_cliente: cliente.nombres,
      tipo_doc: cliente.tipo_doc,
      numero_doc: cliente.numero_doc,
      correo: cliente.correo,
      celular: cliente.celular,
      direccion: cliente.direccion
    });
    setNumeroDoc('');
    setIsModalVisible(false);
    setIsClienteGeneralDisabled(cliente.numero_doc === '7777777');
  };

  const modalColumns = [
    {
      title: 'Número Documento',
      dataIndex: 'numero_doc',
      key: 'numero_doc',
    },
    {
      title: 'Nombres',
      dataIndex: 'nombres',
      key: 'nombres',
    },
    {
      title: 'Acciones',
      key: 'acciones',
      render: (text, record) => (
        <Button type="link" onClick={() => handleSuggestionClick(record)}>
          Seleccionar
        </Button>
      ),
    },
  ];

  if (loading || saving) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh'
        }}
      >
        <Spin size='large' tip='Cargando...' />
      </div>
    );
  }

  return (
    <>
      <Card style={{ background: 'rgba(251, 251, 251)' }} className='animated with-shadow'>
        <div className='header mb-3'>
          <Button type='primary' ghost icon={<DoubleLeftOutlined />} onClick={() => navigate(-1)}>
            Regresar
          </Button>
          <h6 className='mt-6 mb-3'>INFORMACIÓN DEL VEHÍCULO</h6>
        </div>
        <div className='info stack'>
          <Form
            form={form}
            layout='vertical'
            onFinish={handleSubmit}
            initialValues={{
              placa: cliente?.placa || '',
              tipo_vehiculo: cliente?.descripcion || '',
              nombre_cliente: cliente?.nombres || '',
              tipo_doc: cliente?.tipo_doc || '',
              numero_doc: cliente?.numero_doc || '',
              correo: cliente?.correo || '',
              celular: cliente?.celular || '',
              direccion: cliente?.direccion || ''
            }}
          >
            <div className='row'>
              <div className='col-md-2 col-sm-12'>
                <FormItem
                  name='placa'
                  label='Placa'
                  rules={[{ required: true, message: 'La placa es obligatoria' }]}
                >
                  <Input
                    placeholder='Placa'
                    style={{ textTransform: 'uppercase' }}
                    onChange={handlePlacaChange}
                    disabled={isClienteGeneralDisabled}
                  />
                </FormItem>
              </div>
              <div className='col-md-2 col-sm-12'>
                <FormItem
                  name='tipo_vehiculo'
                  label='Tipo Vehículo'
                  rules={[{ required: true, message: 'Tipo de vehículo es obligatorio' }]}
                >
                  <Select
                    placeholder='Seleccione un tipo Vehículo'
                    options={tipovehiculo.map((vehiculo) => ({
                      label: vehiculo.descripcion,
                      value: vehiculo.id
                    }))}
                    disabled={isClienteGeneralDisabled}
                  ></Select>
                </FormItem>
              </div>
              <div className='col-md-3 col-sm-12'>
                <FormItem
                  name='nombre_cliente'
                  label='Nombres Cliente'
                  rules={[{ required: true, message: 'El nombre es obligatorio' }]}
                >
                  <Input
                    placeholder='Nombres Cliente'
                    disabled={isClienteGeneralDisabled}
                  />
                </FormItem>
              </div>
              <div className='col-md-2 col-sm-12'>
                <FormItem
                  name='tipo_doc'
                  label='Tipo de Documento'
                  rules={[{ required: true, message: 'Tipo de documento es obligatorio' }]}
                >
                  <Select
                    placeholder='Seleccione un tipo de documento'
                    disabled={isClienteGeneralDisabled}
                  >
                    <Option value='CC'>CC</Option>
                    <Option value='NIT'>NIT</Option>
                  </Select>
                </FormItem>
              </div>
              <div className='col-md-3 col-sm-12'>
                <FormItem
                  name='numero_doc'
                  label='Número de Documento'
                  rules={[{ required: true, message: 'El número de documento es obligatorio' }]}
                >
                  <Input
                    placeholder='Número de documento'
                    addonBefore={
                      <Button
                        type='link'
                        icon={<SearchOutlined />}
                        onClick={openModal}
                        style={{ height: '20px' }}
                      
                      />
                    }
                  />
                </FormItem>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-3 col-sm-12'>
                <FormItem
                  name='correo'
                  label='Correo Electrónico'
                  rules={[
                    { required: true, message: 'El correo electrónico es obligatorio' },
                    { type: 'email', message: 'El correo electrónico no es válido' }
                  ]}
                >
                  <Input
                    placeholder='Correo Electrónico'
                    disabled={isClienteGeneralDisabled}
                    addonAfter={
                      <Button
                        type='link'
                        icon={<MailOutlined />}
                        onClick={handleSetDefaultEmail}
                        style={{ height: '20px' }}
                      />
                    }
                  />
                </FormItem>
              </div>
              <div className='col-md-2 col-sm-12'>
                <FormItem
                  name='celular'
                  label='Celular'
                  rules={[{ required: true, message: 'El número de celular es obligatorio' }]}
                >
                  <Input
                    placeholder='# Celular'
                    disabled={isClienteGeneralDisabled}
                  />
                </FormItem>
              </div>
              <div className='col-md-7 col-sm-12'>
                <FormItem name='direccion' label='Dirección'>
                  <Input
                    placeholder='Dirección'
                    disabled={isClienteGeneralDisabled}
                  />
                </FormItem>
              </div>
            </div>
          </Form>
          <div>
            <Button
              icon={<CloseCircleFilled />}
              style={{ marginRight: '10px', marginBottom: '20px' }}
              onClick={() => navigate(-1)}
            >
              Cancelar
            </Button>
            <Button icon={<PlusSquareOutlined />} type='primary' onClick={handleSave}>
              Guardar
            </Button>
          </div>
        </div>
      </Card>

      <Modal
        title="Buscar Cliente"
        open={isModalVisible}
        onCancel={closeModal}
        footer={null}
      >
        <Input
          placeholder='Buscar Documento'
          value={numeroDoc}
          onChange={handleNumeroDocChange}
          autoFocus
          style={{ marginBottom: '10px' }}
        />
        {numeroDoc && (
          <Table
            columns={modalColumns}
            dataSource={clienteSuggestions}
            loading={loadingCliente}
            rowKey='numero_doc'
            pagination={{ pageSize: 5 }}
          />
        )}
      </Modal>
    </>
  );
};

export default PerfilVehiculoPage;



