import { useState } from 'react';
import { API_BASE_URL } from '../config/apiConfig';

export function useActualizarListaPrecio() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isUpdated, setIsUpdated] = useState(false);

  const updateListaPrecio = async (listaPrecioData) => {
    const { id, id_precio_lista, precio_publico } = listaPrecioData;

    if (!id || !id_precio_lista) {
      setError('Los IDs del producto y lista de precios son requeridos para actualizar.');
      console.error('No se proporcionaron los IDs requeridos.');
      return null;
    }

    const requestData = {
      action: 'actualizarlp',
      id,
      id_precio_lista,
      precio_publico,
    };

    setLoading(true);
    setIsUpdated(false);
    try {

      const response = await fetch(`${API_BASE_URL}/producto`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestData)
      });

      if (!response.ok) {
        throw new Error(`Error HTTP! Estado: ${response.status}`);
      }

      const contentType = response.headers.get('content-type');
      if (contentType && contentType.includes('application/json')) {
        const data = await response.json();
        console.log('Datos de Respuesta:', data);

   
        if (data.message === 'Lista de precios actualizada o insertada con éxito') {
          setIsUpdated(true);
          return data;
        } else {
          throw new Error(data.error || 'Error al actualizar/insertar la lista de precios');
        }
      } else {
        throw new Error('Tipo de contenido inválido: se esperaba application/json');
      }
    } catch (error) {
      setError(error instanceof Error ? error.message : 'Ha ocurrido un error');
      console.error('Error al actualizar/inserir:', error);
      return null;
    } finally {
      setLoading(false);
    }
  };

  return { updateListaPrecio, loading, error, isUpdated };
}