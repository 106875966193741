import { useEffect, useState } from "react";

import { API_BASE_URL } from "../config/apiConfig";

export const useTipoVehiculo = () => {
  const [tipovehiculo, setTipoVehiculo] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTipoVehiculo = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${API_BASE_URL}/controles?type=tipo_vehiculo`);
        if (!response.ok) {
          throw new Error(`API call failed with status: ${response.status}`);
        }
        const data = await response.json();
        setTipoVehiculo(data);
      } catch (error) {
        console.error('Error fetching tipo vehículo:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTipoVehiculo();
  }, []);
  console.log(tipovehiculo);
  console.log(loading);
  return { tipovehiculo, loading };
};
