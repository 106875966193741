interface Env {
  REACT_APP_API_BASE_URL: string;
  NODE_ENV: string;
}

const env: Env = process.env as unknown as Env;

const FRONT_HOST = window.location.hostname;
const METHOD = window.location.protocol;

const PREFIX_API = env.NODE_ENV === 'development' ? '' : 'api.';
const PREFIX_IMAGES = env.NODE_ENV === 'development' ? '' : 'images.';

const API_PORT = env.NODE_ENV === 'development' ? ':3000' : '';
const IMAGE_SERVER_PORT = env.NODE_ENV === 'development' ? ':3003' : '';

export const API_BASE_URL: string = `${METHOD}//${PREFIX_API}${FRONT_HOST}${API_PORT}/api`;

export const IMAGE_SERVER_URL: string = `${METHOD}//${PREFIX_IMAGES}${FRONT_HOST}${IMAGE_SERVER_PORT}`;
