import React, { useState, useEffect } from 'react';
import { Button, Table, Input, Tag, Modal, message } from 'antd';
import {
  DeleteOutlined,
  DollarCircleOutlined,
  FilterFilled,
  FilterOutlined,
  PlusSquareOutlined,
  SyncOutlined
} from '@ant-design/icons';
import { ColumnProps } from 'antd/es/table';
import useObtenerIngresos from '../../hooks/useObtenerIngresos';
import { useGetPlacaByPlaca } from '../../hooks/useGetPlacaByPlaca';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import EntregarIngresoPage from './EntregarIngreso';
import { useActualizarEstadoIngreso } from '../../hooks/useActualizarEstadoIngreso';
import './IngresosTable.scss';

const IngresosTable = ({
  id,
  fecha_inicio,
  fecha_fin,
  estados,
  setTotalRegistros,
  setTotalValor,
  onOpenModal,
  resetFiltersAndReload,
}) => {
  const navigate = useNavigate();
  const { ingresos, cargando, error } = useObtenerIngresos(id, fecha_inicio, fecha_fin, estados);
  const [placa, setPlaca] = useState('');
  const { data: placas } = useGetPlacaByPlaca(placa);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedIngresoId, setSelectedIngresoId] = useState(null);
  const { actualizarEstadoIngreso } = useActualizarEstadoIngreso();

  const tipoEstado = [
    { text: 'Ingresado', value: 'ingresado' },
    { text: 'Entregado', value: 'entregado' },
    { text: 'Anulado', value: 'anulado' }
  ];

  const handleCrearIngreso = () => {
    navigate('/vertical/proceso-ingreso');
  };

  const handleInputChange = (e) => {
    const value = e.target.value.toUpperCase();
    setPlaca(value);
  };

  const handleSugerenciaClick = (selectedPlaca) => {
    navigate(`/vertical/proceso-ingreso/${selectedPlaca}`);
  };

  useEffect(() => {
    if (ingresos) {
      setTotalRegistros(ingresos.length);
      const sumaTotal = ingresos.reduce((acc, curr) => {
        const valor = Number(curr.valor_total) || 0;
        return acc + valor;
      }, 0);
      setTotalValor(sumaTotal);
    }
  }, [ingresos, setTotalRegistros, setTotalValor]);

  const handleTableChange = (pagination, filters, sorter, extra) => {
    if (extra.action === 'filter') {
      const filteredData = extra.currentDataSource;
      const totalRegistrosFiltrados = filteredData.length;
      const sumaTotalFiltrada = filteredData.reduce((acc, curr) => {
        const valor = Number(curr.valor_total) || 0;
        return acc + valor;
      }, 0);
      setTotalRegistros(totalRegistrosFiltrados);
      setTotalValor(sumaTotalFiltrada);
    }
  };

  const handleOpenModal = (ingresoId) => {
    setSelectedIngresoId(ingresoId);
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
    setSelectedIngresoId(null);
  };

  const handleDeleteClick = (ingreso) => {
    if (ingreso.estado === 'entregado' || ingreso.estado === 'anulado') {
      message.error('No se puede anular un ingreso con estado Entregado o ya anulado.');
      return;
    }
    
    Modal.confirm({
      title: <span style={{ fontSize: '18px', fontWeight: 'bold', margin:'10px' }}>Confirmar Anulación</span>,
      content: (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
          <p style={{ fontSize: '16px', marginBottom: '10px' }}>¿Desea anular el ingreso?</p>
      
        </div>
      ),
      okText: <span style={{ fontSize: '14px' }}>Aceptar</span>,
      cancelText: <span style={{ fontSize: '14px' }}>Cancelar</span>,
      width: 400,
      style: { top: 20 },
      okButtonProps: {
        style: { margin: '10px' }
      },
      cancelButtonProps: {
        style: { margin: '10px' }
      },
      onOk: async () => {
        try {
          await actualizarEstadoIngreso(ingreso.ingreso_id, 'anulado');
          message.success('Ingreso anulado con éxito');
        } catch (error) {
          message.error(`Error al anular el ingreso: ${error}`);
        }
      },
      onCancel() {
        console.log('Cancelado');
      }
    });
  };

  const columns: ColumnProps<any>[] = [
    {
      key: 'actions',
      title: 'Acciones',
      width: 100,
      render: (ingreso) => (
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100px' }}>
          <Button
            icon={<DollarCircleOutlined />}
            shape='circle'
            style={{ color: 'greenyellow' }}
            onClick={() => handleOpenModal(ingreso.ingreso_id)}
          />
          <Button
            icon={<DeleteOutlined />}
            shape='circle'
            danger
            onClick={() => handleDeleteClick(ingreso)}
          />
        </div>
      )
    },
    {
      key: 'ingreso_id',
      dataIndex: 'ingreso_id',
      title: 'Ingreso'
    },
    {
      key: 'placa',
      dataIndex: 'vehiculo_placa',
      title: 'Placa'
    },
    {
      key: 'servicios',
      dataIndex: 'servicios',
      title: 'Servicios',
      render: (text) => <span style={{ fontSize: '10px' }}>{text}</span>
    },
    {
      key: 'valor_total',
      dataIndex: 'valor_total',
      title: 'Valor',
      render: (valor) => (
        <div style={{ textAlign: 'right' }}>
          <span className='nowrap' style={{ color: '#336cfb' }}>
            {new Intl.NumberFormat('es-CO', { style: 'currency', currency: 'COP' }).format(valor)}
          </span>
        </div>
      )
    },
    {
      key: 'cliente_nombre',
      dataIndex: 'cliente_nombre',
      title: 'Cliente'
    },
    {
      key: 'celular',
      dataIndex: 'celular',
      title: 'Celular',
      render: (phone) => (
        <span className='d-flex align-baseline nowrap' style={{ color: '#336cfb' }}>
          <span className='icofont icofont-ui-cell-phone mr-1' style={{ fontSize: 16 }} /> {phone} </span>
      )
    },
    {
      key: 'estado',
      dataIndex: 'estado',
      title: 'Estado',
      filters: tipoEstado,
      onFilter: (value, record) => record.estado === value,
      filterMultiple: true,
      render: (estado) => {
        let color;
        switch (estado) {
          case 'ingresado':
            color = '#FFC300';
            break;
          case 'entregado':
            color = '#b7ce63';
            break;
          case 'anulado':
            color = '#ff7f7f';
            break;
          default:
            color = '#d9d9d9';
        }

        return (
          <Tag style={{ borderRadius: 20 }} color={color}>
            {estado}
          </Tag>
        );
      },
      filterIcon: (filtered) => (
        <FilterFilled style={{ color: filtered ? '#ff4d4f' : '#ff4d4f' }} />
      )
    },
    {
      key: 'fecha_creacion',
      dataIndex: 'fecha_creacion',
      title: 'Fecha Creación',
      render: (fecha) => {
        const formattedDate = moment.utc(fecha).format('DD/MM/YYYY HH:mm:ss');
        return <span style={{ fontSize: '10px' }}>{formattedDate}</span>;
      }
    }
  ];

  if (error) {
    return <p>Error al cargar los datos: {error.message}</p>;
  }

  const pagination = ingresos?.length <= 10 ? false : {};

  return (
    <>
      <div className='row'>
        <div className='col-md-2 col-sm-12' style={{ position: 'relative' }}>
          <Input
            placeholder='Buscar Placa'
            style={{ marginBottom: '10px', textTransform: 'uppercase' }}
            value={placa}
            onChange={handleInputChange}
          />
          {placa && placas && placas.length > 0 && (
            <ul className='suggestions-list'>
              {placas.map((p) => (
                <li key={p} onClick={() => handleSugerenciaClick(p)}>
                  {p}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
      <div className='row'>
        <div className='mb-0'>
          <Button
            type='primary'
            icon={<PlusSquareOutlined />}
            onClick={handleCrearIngreso}
            style={{ width: '40px', marginBottom: '10px', marginRight: '10px', marginLeft: '10px' }}
          />
          <Button
            type='primary'
            onClick={onOpenModal}
            icon={<FilterOutlined />}
            style={{ width: '40px', marginBottom: '10px', marginLeft: '10px' }}
          />
          <Button
            type='primary'
            onClick={resetFiltersAndReload}
            icon={<SyncOutlined />}
            style={{ width: '40px', marginBottom: '10px', marginLeft: '20px' }}
          />
        </div>
      </div>
      <Table
        loading={cargando}
        className='accent-header'
        dataSource={ingresos}
        columns={columns}
        rowKey='id'
        pagination={pagination}
        onChange={handleTableChange}
      />
      <EntregarIngresoPage
        ingresoId={selectedIngresoId}
        visible={modalVisible}
        onClose={handleCloseModal}
      />
    </>
  );
};

export default IngresosTable;



