import React, { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

interface ProtectedRouteProps {
  children: ReactNode;
  allowedRoles?: string[];
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children, allowedRoles = [] }) => {
  const { user, isLoading } = useAuth();

  if (isLoading) {
    return <div>Cargando...</div>;
  }

  if (!user || (allowedRoles.length > 0 && !allowedRoles.includes(user.rol))) {
    return <Navigate to="/public/sign-in" replace />;
  }

  return <>{children}</>;
};

export default ProtectedRoute;