import React, { useState, useEffect } from 'react';
import { Table, InputNumber, Button, notification } from 'antd';
import { FormOutlined } from '@ant-design/icons';
import { useListaPrecioById } from '../../hooks/useObtenerListaPrecio';
import { useActualizarListaPrecio } from '../../hooks/useActualizarListaPrecio';

const ListaPreciosTable = ({ idProducto }) => {
  const { listaprecio, loading, error } = useListaPrecioById(idProducto);
  const { updateListaPrecio, loading: updating } = useActualizarListaPrecio();
  const [editableListaprecio, setEditableListaprecio] = useState([]);


  useEffect(() => {
    if (listaprecio) {
      setEditableListaprecio(listaprecio);
    }
  }, [listaprecio]);

  const handlePriceChange = (idLista, newPrice) => {
    setEditableListaprecio((prevLista) =>
      prevLista.map((item) =>
        item.lista_precio_id === idLista ? { ...item, precio_publico: newPrice } : item
      )
    );
  };

  const handleActualizar = async (record) => {

    const { lista_precio_id, precio_publico } = record;

    const success = await updateListaPrecio({
      id: idProducto, 
      id_precio_lista: lista_precio_id, 
      precio_publico 
    });

   
    if (success) {
      notification.error({ message: 'Error al actualizar el precio' });
    } else {
      notification.success({ message: 'Precio actualizado correctamente' });
    }
  };


  const columns = [
    {
      title: 'Id Lista Precio',
      dataIndex: 'lista_precio_id',
      key: 'lista_precio_id'
    },
    {
      title: 'Descripción',
      dataIndex: 'descripcion',
      key: 'descripcion'
    },
    {
      title: 'Precio Público',
      key: 'precio_publico',
      render: (record) => (
        <InputNumber
          value={record.precio_publico}
          formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
          style={{ width: '100%' }}
          onChange={(value) => handlePriceChange(record.lista_precio_id, value)}
        />
      )
    },
    {
      key: 'actions',
      title: 'Actualizar',
      render: (record) => (
        <Button
          icon={<FormOutlined />}
          shape='circle'
          type='primary'
          loading={updating}
          onClick={() => handleActualizar(record)}
        />
      )
    },
  ];

 
  if (loading) return <div>Cargando lista de precios...</div>;
  if (error) return <div>Error: {error}</div>;


  return (
    <Table
      columns={columns}
      dataSource={editableListaprecio}
      rowKey='lista_precio_id'
      pagination={false}
    />
  );
};

export default ListaPreciosTable;
