import { MailOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Form, FormInstance, Input, Select, Switch, Modal, Table, message } from "antd";
import React, { forwardRef, useCallback, useEffect, useState } from "react";
import { useGetClienteById } from "../../../hooks/useGetClienteById";
import { useClienteVehiculoByPlaca } from "../../../hooks/useClienteVehiculoByPlaca";
import { useTipoVehiculo } from "../../../hooks/useTipoVehiculo";
import { useGetClienteByNumeroDoc } from "../../../hooks/useGetClienteByNumeroDoc";
import { useValidarIngreso } from "../../../hooks/useValidarIngreso"; // Importa el hook
import { useNavigate } from "react-router-dom"; // Importa useNavigate

interface IngresoClientePageProps {
  placa: string;
  form: FormInstance;
}

const IngresoClientePage = forwardRef<FormInstance, IngresoClientePageProps>(({ placa, form }) => {
  const [switchValues, setSwitchValues] = useState({
    clienteGeneral: false,
    aceptaEnvioInfo: false
  });
  const [fields, setFields] = useState<Record<string, any>>({});
  const [clienteId, setClienteId] = useState<string | null>(null);
  const { cliente: clientePorId } = useGetClienteById(clienteId);
  const { cliente: clientePorPlaca } = useClienteVehiculoByPlaca(placa);
  const { tipovehiculo } = useTipoVehiculo();
  const [isClienteGeneralDisabled, setIsClienteGeneralDisabled] = useState(false);
  const [isDoc7777777, setIsDoc7777777] = useState(false);

  const [numeroDoc, setNumeroDoc] = useState('');
  const { data: clienteSuggestions, loading: loadingCliente, error: errorCliente } = useGetClienteByNumeroDoc(numeroDoc);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const navigate = useNavigate(); 
  const { data: validacionIngreso } = useValidarIngreso(placa); 

  useEffect(() => {
    if (validacionIngreso && validacionIngreso.estado === 'ingresado') {
      message.error('La placa del vehículo digitado ya está ingresada, revisar información');
      navigate(-1); 
    }
  }, [validacionIngreso, navigate]);

  const handleSwitchChange = (checked, name) => {
    const newSwitchValues = { ...switchValues, [name]: checked };
    setSwitchValues(newSwitchValues);
    localStorage.setItem('switchValues', JSON.stringify(newSwitchValues));

    if (name === 'clienteGeneral') {
      if (checked) {
        setClienteId('533');
      } else {
        const currentValues = form.getFieldsValue();
        form.setFieldsValue({
          placa: currentValues.placa,
          tipo_vehiculo: currentValues.tipo_vehiculo,
          nombres: '',
          tipo_doc: '',
          numero_doc: '',
          correo: '',
          celular: '',
          direccion: ''
        });
        setClienteId(null);
        setIsDoc7777777(false); 
      }
    }
  };

  const updateForm = useCallback(
    (key, value) => {
      form.setFieldsValue({
        [key]: value
      });
      setFields((prev) => ({ ...prev, [key]: value }));
    },
    [form]
  );

  const handleSetDefaultEmail = useCallback(() => {
    form.setFieldsValue({
      correo: 'facturag4@gmail.com'
    });
  }, [form]);

  const handleChange = (key) => (value) => {
    switch (key) {
      case 'tipo_vehiculo':
        const descripcion = tipovehiculo.find((vehiculo) => vehiculo.id === value)?.descripcion;
        updateForm('descripcion', descripcion);
        updateForm(key, value);
        break;
      default:
        updateForm(key, value);
        break;
    }
    console.log('handleChange', form.getFieldsValue());
  };

  const handlePlacaChange = (e) => {
    const uppercasedValue = e.target.value.toUpperCase();
    updateForm('placa', uppercasedValue);
  };

  const handlePlacaBlur = () => {
    const currentValue = form.getFieldValue('placa');
    if (currentValue) {
      updateForm('placa', currentValue.toUpperCase());
    }
  };

  const handleNumeroDocChange = (e) => {
    const value = e.target.value;
    setNumeroDoc(value);
    updateForm('numero_doc', value);
  };

  const handleSuggestionClick = (cliente) => {
    form.setFieldsValue(cliente);
    setNumeroDoc('');
    setIsModalVisible(false);
  };

  const openModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  const modalColumns = [
    {
      title: 'Número Documento',
      dataIndex: 'numero_doc',
      key: 'numero_doc',
    },
    {
      title: 'Nombres',
      dataIndex: 'nombres',
      key: 'nombres',
    },
    {
      title: 'Acciones',
      key: 'acciones',
      render: (text, record) => (
        <Button type="link" onClick={() => handleSuggestionClick(record)}>
          Seleccionar
        </Button>
      ),
    },
  ];

  useEffect(() => {
    const savedSwitchValues = localStorage.getItem('switchValues');
    if (savedSwitchValues) {
      setSwitchValues(JSON.parse(savedSwitchValues));
    }
  }, []);

  useEffect(() => {
    if (clientePorPlaca) {
      setFields(clientePorPlaca);
      form.setFieldsValue(clientePorPlaca);
    }
  }, [clientePorPlaca, form]);

  useEffect(() => {
    if (clientePorId) {
      setFields(clientePorId);
      form.setFieldsValue(clientePorId);
    }
  }, [clientePorId, form]);

  useEffect(() => {
    const fields = form.getFieldsValue();
    setIsClienteGeneralDisabled(!!fields.nombres || !!clientePorPlaca);
    if (fields.numero_doc === '7777777') {
      setIsDoc7777777(true);
    } else {
      setIsDoc7777777(false);
    }
  }, [form, clientePorPlaca]);

  return (
    <>
      <Form
        layout='vertical'
        form={form}
        onValuesChange={(changedFields) => {
          const [key, value] = Object.entries(changedFields)[0];
          handleChange(key)(value);
        }}
        initialValues={fields}
      >
        <div className='row'>
          <div className='col-md-2 col-sm-12'>
            <Form.Item
              name='placa'
              rules={[
                { required: true, message: 'La placa es obligatoria' },
                { len: 6, message: 'Placa debe tener 6 caracteres' }
              ]}
            >
              <Input
                name='placa'
                placeholder='Placa'
                autoComplete='off'
                style={{ textTransform: 'uppercase' }}
                onChange={handlePlacaChange}
                onBlur={handlePlacaBlur}
              />
            </Form.Item>
          </div>
          <div className='col-md-2 col-sm-12'>
            <Form.Item
              name='tipo_vehiculo'
              rules={[{ required: true, message: 'Tipo de vehículo es obligatorio' }]}
            >
              <Select
                placeholder='Seleccione un tipo Vehículo'
                options={tipovehiculo.map((vehiculo) => ({
                  label: vehiculo.descripcion,
                  value: vehiculo.id
                }))}
              ></Select>
            </Form.Item>
          </div>
          <div className='col-md-3 col-sm-12'>
            <Form.Item
              name='nombres'
              rules={[{ required: true, message: 'El Nombre es obligatorio' }]}
            >
              <Input
                name='nombres'
                placeholder='Nombres Cliente'
                autoComplete='off'
                disabled={switchValues.clienteGeneral || isDoc7777777}
              />
            </Form.Item>
          </div>
          <div className='col-md-2 col-sm-12'>
            <Form.Item
              name='tipo_doc'
              rules={[{ required: true, message: 'Tipo de documento es obligatorio' }]}
            >
              <Select
                placeholder='Seleccione un tipo de documento'
                disabled={switchValues.clienteGeneral || isDoc7777777}
                onChange={handleChange}
              >
                <Select.Option value='CC'>CC</Select.Option>
                <Select.Option value='NIT'>NIT</Select.Option>
              </Select>
            </Form.Item>
          </div>
          <div className='col-md-3 col-sm-12'>
            <Form.Item
              name='numero_doc'
              rules={[{ required: true, message: 'El número de documento es obligatorio' }]}
            >
              <Input
                name='numero_doc'
                placeholder='Número de documento'
                autoComplete='off'
                disabled={switchValues.clienteGeneral || isDoc7777777}
                addonBefore={
                  <Button
                    type='link'
                    icon={<SearchOutlined />}
                    onClick={openModal}
                    disabled={switchValues.clienteGeneral || isDoc7777777}
                    style={{ height: '20px' }}
                  />
                }
              />
            </Form.Item>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-3 col-sm-12'>
            <Form.Item
              name='correo'
              rules={[
                { required: true, message: 'El correo electrónico es obligatorio' },
                { type: 'email', message: 'El correo electrónico no es válido' }
              ]}
            >
              <Input
                name='correo'
                type='email'
                placeholder='Correo Electrónico'
                disabled={switchValues.clienteGeneral || isDoc7777777}
                addonAfter={
                  <Button
                    type='link'
                    icon={<MailOutlined />}
                    onClick={handleSetDefaultEmail}
                    style={{ height: '20px' }}
                  />
                }
              />
            </Form.Item>
          </div>
          <div className='col-md-2 col-sm-12'>
            <Form.Item
              name='celular'
              rules={[{ required: true, message: 'El número de documento es obligatorio' }]}
            >
              <Input
                name='celular'
                placeholder='# Celular'
                autoComplete='off'
                disabled={switchValues.clienteGeneral || isDoc7777777}
              />
            </Form.Item>
          </div>
          <div className='col-md-7 col-sm-12'>
            <Form.Item name='direccion'>
              <Input
                name='direccion'
                placeholder='Dirección'
                autoComplete='off'
                disabled={switchValues.clienteGeneral || isDoc7777777}
              />
            </Form.Item>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-2 col-sm-12'>
            <Form.Item>
              <div className='field-with-label'>
                <Switch
                  checked={switchValues.clienteGeneral}
                  onChange={(checked) => handleSwitchChange(checked, 'clienteGeneral')}
                  disabled={isClienteGeneralDisabled || isDoc7777777}
                />
                <span className='label'>Cliente General</span>
              </div>
            </Form.Item>
          </div>
          <div className='col-md-4 col-sm-12'>
            <Form.Item>
              <div className='field-with-label'>
                <Switch
                  checked={switchValues.aceptaEnvioInfo}
                  onChange={(checked) => handleSwitchChange(checked, 'aceptaEnvioInfo')}
                  disabled={isDoc7777777}
                />
                <span className='label'>Acepta envío de información a email</span>
              </div>
            </Form.Item>
          </div>
        </div>
      </Form>

      <Modal
        title="Buscar Cliente"
        open={isModalVisible}
        onCancel={closeModal}
        footer={null}
      >
        <Input
          placeholder='Buscar Documento'
          value={numeroDoc}
          onChange={handleNumeroDocChange}
          autoFocus
          style={{ marginBottom: '10px' }}
        />
        {numeroDoc && (
          <Table
            columns={modalColumns}
            dataSource={clienteSuggestions}
            loading={loadingCliente}
            rowKey='numero_doc'
            pagination={{ pageSize: 5 }}
          />
        )}
      </Modal>
    </>
  );
});

export default IngresoClientePage;












