import { useState } from 'react';
import { API_BASE_URL } from '../config/apiConfig';

export function useInsertUser() {
    const [isInsert, setIsInsert] = useState(false);
    const [result, setResult] = useState(null);
    const [error, setError] = useState<string | null>(null);

    const insertUser = async (userData) => {
        setIsInsert(true);
        try {
            const response = await fetch(`${API_BASE_URL}/usuario`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    action: 'insertar',
                    ...userData
                })
            });

            if (!response.ok) {
                throw new Error(`Network response was not ok: ${response.statusText}`);
            }

            const data = await response.json();
            setResult(data);
            if (data.success && data.id) {
                return data.id;  
            } else {
                throw new Error('Failed to retrieve ID from response');
            }
        } catch (error) {
            setError(error as string);
        } finally {
            setIsInsert(false);
        }
    };

    return { insertUser, result, isInsert, error };
}