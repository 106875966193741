import { useState } from 'react';
import { API_BASE_URL } from '../config/apiConfig';

export interface Cliente {
    id_cliente_erp?: string;
    nombres: string;
    tipo_doc: string;
    numero_doc: string;
    correo: string;
    celular: string;
    tipo_persona?: string;
    regimen?: string;
    id_pais?: number;
    id_departamento?: number;
    id_municipio?: number;
    direccion?: string;
}

export interface Vehiculo {
    placa: string;
    tipo_vehiculo: string;
    observacion?: string;
}

export interface HistoricoVehiculo {
    imagen_defecto: string;
    observacion: string;
}

export interface ProductoPorIngreso {
    producto_id?: number;
    precio_con_iva?: number;
    precio_antes_iva?: number;
    porcentaje_descuento?: number;
    valor_descuento?: number;
    neto?: number;
    impuesto?: number; 
}

export interface Ingreso {
    usuario: string;
    estado: string;
    clave: string;
    valor_total: number;
    acepta_terminos: boolean;
    fecha_sms: Date | string;
    fecha_creacion: Date | string;
    envio_sms: boolean;
    productos: ProductoPorIngreso[];
}

export interface UpsertServicioIngresoResponse {
    success: boolean;
    error?: string;
}

const useUpsertServicioIngreso = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [success, setSuccess] = useState<boolean | null>(null);

    const convertToBogotaTime = (date: Date | string): string => {
        const localDate = new Date(date);
        const bogotaOffset = -5 * 60; // -5 hours in minutes
        const utcDate = new Date(localDate.getTime() + (localDate.getTimezoneOffset() * 60000));
        const bogotaDate = new Date(utcDate.getTime() + (bogotaOffset * 60000));
        return bogotaDate.toISOString();
    };

    const upsertServicioIngreso = async (
        clienteData: Cliente,
        vehiculoData: Vehiculo,
        historicoVehiculoData: HistoricoVehiculo,
        ingresoData: Ingreso
    ) => {
        setLoading(true);
        setError(null);
        setSuccess(null);

        const adjustedIngresoData = {
            ...ingresoData,
            fecha_sms: convertToBogotaTime(ingresoData.fecha_sms),
            fecha_entrega: convertToBogotaTime(ingresoData.fecha_creacion)
        };

        try {
            const response = await fetch(`${API_BASE_URL}/ingresos`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    clienteData,
                    vehiculoData,
                    historicoVehiculoData,
                    ingresoData: adjustedIngresoData
                })
            });

            const data: UpsertServicioIngresoResponse = await response.json();

            if (data.success) {
                setSuccess(true);
            } else {
                setError(data.error || 'Error desconocido');
            }
        } catch (err) {
            setError('Error al conectar con el servidor');
        } finally {
            setLoading(false);
        }
    };

    return {
        upsertServicioIngreso,
        loading,
        error,
        success
    };
};

export default useUpsertServicioIngreso;
