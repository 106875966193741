import { useState } from 'react';
import { API_BASE_URL } from '../config/apiConfig';

interface IActualizarIdClienteErp {
  id_cliente_erp: string;
  numero_doc: string;
}

export function useActualizarIdClienteErp() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isUpdated, setIsUpdated] = useState(false);

  const actualizarIdClienteErp = async (userData: IActualizarIdClienteErp) => {
    if (!userData.numero_doc) {
      setError("El número de documento es requerido para actualizar.");
      console.error("No numero_doc provided for updating.");
      return null;
    }

    setLoading(true);
    setIsUpdated(false);
    try {
      const response = await fetch(`${API_BASE_URL}/clientes`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ ...userData, action: "actualizarIdClienteErp" })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      
      const contentType = response.headers.get('content-type');
      if (contentType && contentType.includes('application/json')) {
        const data = await response.json();
        console.log("Response Data:", data); 
        
        if (data.message) {
          setIsUpdated(true);
          return data;
        } else {
          throw new Error(data.error || 'Error actualizando el ID Cliente ERP');
        }
      } else {
        throw new Error('Invalid content type: Expected application/json');
      }
    } catch (error) {
      setError(error instanceof Error ? error.message : 'Ha ocurrido un error');
      console.error("Update Error:", error);
      return null;
    } finally {
      setLoading(false);
    }
  };

  return { actualizarIdClienteErp, loading, error, isUpdated };
}
