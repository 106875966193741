import { useState, useCallback } from 'react';

const useSendMessage = () => {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const sendMessage = useCallback(async (messageData: any) => {
    setLoading(true);
    const options: RequestInit = {
      method: 'POST',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        authorization: 'Basic QVBSSVNBTEFWQURPOkh1c2g4OTUuLm5ldDYyNw==',
      },
      body: JSON.stringify(messageData),
    };

    try {
      const response = await fetch('https://api.messaging-service.com/sms/1/text/single', options);
      const data = await response.json();
      setResponse(data);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  }, []);

  return { sendMessage, response, error, loading };
};

export default useSendMessage;