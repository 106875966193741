import { useState, useEffect } from 'react';
import { API_BASE_URL } from '../config/apiConfig';

export function useObtenerIngresosById(id) {
  const [ingreso, setIngreso] = useState(null);
  const [cargando, setCargando] = useState(false); 
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!id) {
      setError('El ID del ingreso es requerido');
      return;
    }

    async function fetchIngreso() {
      setCargando(true);
      setError(null);

      try {
        const url = `${API_BASE_URL}/ingresos?id=${id}`;
        const respuesta = await fetch(url);

        if (!respuesta.ok) {
          throw new Error('Error en la solicitud');
        }

        const datos = await respuesta.json();
        if (datos.ingreso) {
          setIngreso(datos.ingreso);
        } else {
          throw new Error(datos.message || 'Error al obtener el ingreso');
        }
      } catch (error) {
        setError(error);
      } finally {
        setCargando(false);
      }
    }

    fetchIngreso();
  }, [id]);

  return { ingreso, cargando, error };
}