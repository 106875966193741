import { useState } from 'react';
import { API_BASE_URL } from '../config/apiConfig';

export function useActualizarProducto() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isUpdated, setIsUpdated] = useState(false);

  const updateProducto = async (productoData) => {
    if (!productoData.id) {
      setError('El ID es requerido para actualizar.');
      console.error('No ID provided for updating.');
      return null;
    }

    const requestData = {
      action: 'actualizarpro',
      ...productoData
    };

    setLoading(true);
    setIsUpdated(false);
    try {
      const response = await fetch(`${API_BASE_URL}/producto`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestData)
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const contentType = response.headers.get('content-type');
      if (contentType && contentType.includes('application/json')) {
        const data = await response.json();
        console.log('Response Data:', data);

        if (data.message === 'Producto actualizado con éxito') {
          setIsUpdated(true);
          return data;
        } else {
          throw new Error(data.error || 'Error actualizando el producto');
        }
      } else {
        throw new Error('Invalid content type: Expected application/json');
      }
    } catch (error) {
      setError(error instanceof Error ? error.message : 'Ha ocurrido un error');
      console.error('Update Error:', error);
      return null;
    } finally {
      setLoading(false);
    }
  };

  return { updateProducto, loading, error, isUpdated };
}
