import { useEffect, useState } from 'react';
import { API_BASE_URL } from '../config/apiConfig';

interface Cliente {
  nombres: string;
  tipo_doc: string;
  numero_doc: string;
  correo: string;
  celular: string;
  direccion: string;
}

export function useGetClienteByNumeroDoc(numero_doc: string) {
  const [data, setData] = useState<Cliente[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!numero_doc) {
      setData([]);
      setLoading(false);
      return;
    }

    const fetchCliente = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${API_BASE_URL}/vehiculo?numero_doc=${numero_doc}`, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const result = await response.json();
        if (result.cliente) {
          setData(result.cliente);
        } else {
          setData([]);
        }
      } catch (error: any) {
        console.error(`Error al obtener el cliente: ${error.message}`);
        setError(`Error al obtener el cliente: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchCliente();
  }, [numero_doc]);

  return { data, loading, error };
}
