import React from 'react';
import { Table, Spin, Alert } from 'antd';
import useObtenerIngresosByPlaca from '../../../hooks/useObtenerIngresosByPlaca';

interface BdIngresosProps {
  placa: string;
}

const BdIngresos: React.FC<BdIngresosProps> = ({ placa }) => {
  const { ingresos, loading, error } = useObtenerIngresosByPlaca(placa);

  const columns = [
    {
      title: 'Ingreso',
      dataIndex: 'ingreso_id',
      key: 'ingreso_id',
    },
    {
      title: 'Sucursal',
      dataIndex: 'sucursal_descripcion',
      key: 'sucursal',
      render: (text: string) => (
        <span style={{ color: '#336cfb' }}>{text}</span> 
      ),
    },
    {
      title: 'Servicio',
      dataIndex: 'servicios',
      key: 'servicios',
    },
    {
      title: 'Valor',
      dataIndex: 'valor_total',
      key: 'valor_total',
      render: (valor: number) => (
        <div style={{ textAlign: 'right' }}>
          <span style={{ color: '#336cfb' }}>
            {new Intl.NumberFormat('es-CO', { style: 'currency', currency: 'COP' }).format(valor)}
          </span>
        </div>
      ),
    },
    {
      title: 'Fecha Servicio',
      dataIndex: 'fecha_creacion',
      key: 'fecha_creacion',
      render: (fecha: string) => (
        <div style={{ textAlign: 'right' }}>
          <span>{new Date(fecha).toLocaleDateString('es-CO')}</span>
        </div>
      ),
    },
  ];

  if (loading) {
    return <Spin tip="Cargando..." />;
  }

  if (error) {
    return <Alert message="Error" description={error} type="error" showIcon />;
  }

  return (
    <Table
      className='accent-header'
      dataSource={ingresos || []}
      columns={columns}
      rowKey="ingreso_id"
      pagination={false}
    />
  );
};

export default BdIngresos;