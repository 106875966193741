import { useEffect, useState } from 'react';
import { API_BASE_URL } from '../config/apiConfig';

export function useGetClienteById(id) {
    const [cliente, setCliente] = useState(null);
    const [loading, setLoading] = useState(true); // Iniciar con true si esperas cargar datos inmediatamente
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!id) {
            setLoading(false); // Si no hay ID, no hay carga por hacer
            return;
        }

        const fetchCliente = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}/clientes`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ action: 'obtener', id })
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const { cliente } = await response.json();
                if (cliente) {
                    setCliente(cliente);
                } else {
                    throw new Error('Cliente no encontrado');
                }
            } catch (error) {
                setError(`Error al obtener el cliente: ${error}`);
            } finally {
                setLoading(false);
            }
        };

        fetchCliente();
    }, [id]);

    return { cliente, loading, error };
}