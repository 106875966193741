import { useState } from 'react';
import { API_BASE_URL } from '../config/apiConfig';

export function useActualizarEstadoIngreso() {
    const [loading, setLoading] = useState(false); 
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);

    const actualizarEstadoIngreso = async (id, estado) => { 
        setLoading(true);
        setError(null);
        setSuccess(false);

        try {
            const response = await fetch(`${API_BASE_URL}/ingresos`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ id, estado })
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const result = await response.json();

            if (result.success) {
                setSuccess(true);
            } else {
                throw new Error('No se pudo actualizar el estado del ingreso');
            }
        } catch (error) {
            setError(`Error al actualizar el estado del ingreso: ${error}`);
        } finally {
            setLoading(false);
        }
    };

    return { actualizarEstadoIngreso, loading, error, success };
}