import { useState, useEffect } from 'react';
import { API_BASE_URL } from '../config/apiConfig';

interface Vehiculo {
  id: number;
  placa: string;
  tipo_vehiculo: string;
  documento_cliente: string;
  nombre_cliente: string;
}

export const useAllVehiculos = () => {
  const [vehiculosAll, setVehiculosAll] = useState<Vehiculo[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchVehiculos = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${API_BASE_URL}/vehiculo`);
        if (!response.ok) {
          throw new Error(`API call failed with status: ${response.status}`);
        }
        const data = await response.json();
        console.log('Datos obtenidos de la API:', data);
        setVehiculosAll(Array.isArray(data.vehiculos) ? data.vehiculos : []);
      } catch (error) {
        console.error('Error obteniendo vehículos:', error);
        setVehiculosAll([]);
      } finally {
        setLoading(false);
      }
    };

    fetchVehiculos();
  }, []);

  return { vehiculosAll, loading };
};

