import React, { createContext, useContext, useState, ReactNode } from 'react';

interface FormData {
  cliente: Record<string, any>;
  vehiculo: Record<string, any>;
  servicio: Record<string, any>;
}

interface FormDataContextType {
  formData: FormData;
  updateFormData: (step: keyof FormData, data: Record<string, any>) => void;
}

const FormDataContext = createContext<FormDataContextType | undefined>(undefined);

export const useFormData = (): FormDataContextType => {
  const context = useContext(FormDataContext);
  if (!context) {
    throw new Error('useFormData must be used within a FormDataProvider');
  }
  return context;
};

interface FormDataProviderProps {
  children: ReactNode;
}

export const FormDataProvider: React.FC<FormDataProviderProps> = ({ children }) => {
  const [formData, setFormData] = useState<FormData>({
    cliente: {},
    vehiculo: {},
    servicio: {},
  });

  const updateFormData = (step: keyof FormData, data: Record<string, any>) => {
    setFormData((prevData) => ({
      ...prevData,
      [step]: data,
    }));
  };

  return (
    <FormDataContext.Provider value={{ formData, updateFormData }}>
      {children}
    </FormDataContext.Provider>
  );
};