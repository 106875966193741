import { useAuth } from '../context/AuthContext';  

export const useUser = () => {
  const { user } = useAuth();

  if (!user) {
    return {
      isAuthenticated: false,
      user: null,
      role: null,
      userId: null,
      empleado: null,
    };
  }

  return {
    isAuthenticated: true,
    user: user,
    role: user.rol,
    userId: user.id,
    userempleado: user.empleado,
   
  };
};
