import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { defaultRoutes } from '../routing'; 

const DefaultRoutes: React.FC = () => (
  <Routes>
    {defaultRoutes.map((route, index) => (
      <Route key={index} path={`/${route.path}`} element={<route.component />} />
    ))}
    <Route path='*' element={<Navigate replace to='/public/page-404' />} />
  </Routes>
);

export default DefaultRoutes; 