import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import ConfigProvider from 'antd/es/config-provider';
import VerticalLayout from './layout/vertical/Vertical';
import HorizontalLayout from './layout/horizontal/Horizontal';
import NotFound from './pages/sessions/404';
import SignIn from './pages/sessions/Sign-in';
import { useHideLoader } from './hooks/useHideLoader';
import { AuthProvider } from './context/AuthContext';
import ProtectedRoute from './components/ProtectedRoute';
import DefaultRoutes from './components/DefaultRoutes'; 
import { FormDataProvider } from './hooks/useFormData';
import './App.scss';

const App: React.FC = () => {
  useHideLoader();

  return (
    <ConfigProvider theme={{ token: { fontFamily: "'Lato', sans-serif" } }}>
      <AuthProvider>
        <FormDataProvider>
          <Routes>
            <Route path="/" element={<Navigate replace to="/vertical/ingresos" />} />
            <Route path="/public/sign-in" element={<SignIn />} />

            <Route path="/horizontal/*" element={
              <HorizontalLayout>
                <ProtectedRoute allowedRoles={['admin', 'user']}>
                  <DefaultRoutes />
                </ProtectedRoute>
              </HorizontalLayout>
            } />

            <Route path="/vertical/*" element={
              <VerticalLayout>
                <ProtectedRoute allowedRoles={['admin', 'user']}>
                  <DefaultRoutes />
                </ProtectedRoute>
              </VerticalLayout>
            } />

            <Route path="*" element={<NotFound />} />
          </Routes>
        </FormDataProvider>
      </AuthProvider>
    </ConfigProvider>
  );
};

export default App;
