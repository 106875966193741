import React from 'react';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { AlertProvider } from './context/AlertContext';

import './index.scss';
import App from './App';

import * as serviceWorker from './serviceWorker';
import { HashRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';

const container = document.getElementById('root'); 
const root = createRoot(container); 

root.render(
  <Provider store={store}>
    <HashRouter>
      <AlertProvider>
        <App />
      </AlertProvider>
    </HashRouter>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
