import React, { useState } from 'react';
import { Button, Form, Input, Select } from 'antd';
import { useInsertCliente } from '../../../hooks/useInsertarCliente';
import { IClienteAll } from '../../../interfaces/clientes';
import { useAlert } from '../../../context/AlertContext';

const ClienteForm: React.FC<{
  onClose: () => void;
  onAddCliente: (cliente: IClienteAll) => void;
}> = ({ onClose, onAddCliente }) => {
  const { insertCliente } = useInsertCliente();
  const alertContext = useAlert();
  const [formData, setFormData] = useState<IClienteAll>({
    nombres: '',
    tipo_doc: '',
    numero_doc: '',
    tipo_persona: '',
    correo: '',
    celular: '',
    direccion: '',
    id_pais: 46,
    id_departamento: 3,
    id_municipio: 12
  });

  const handleChange = (name: keyof IClienteAll, value: any) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleCancel = () => {
    resetForm();
    onClose();
  };

  const resetForm = () => {
    setFormData({
      nombres: '',
      tipo_doc: '',
      numero_doc: '',
      tipo_persona: '',
      correo: '',
      celular: '',
      direccion: '',
      id_pais: 46,
      id_departamento: 3,
      id_municipio: 12
    });
  };

  const handleSubmit = async () => {
    const response = await insertCliente(formData);
    if (response.success) {
      alertContext.triggerAlert({
        type: 'error',
        message: 'Error al crear el cliente',
        description: response.error || 'No se pudo crear el cliente correctamente'
      });
    } else {
      onAddCliente(formData);
      resetForm();
      onClose();
      alertContext.triggerAlert({
        type: 'success',
        message: 'Cliente creado correctamente',
        description: 'El cliente fue creado correctamente'
      });
    }
  };

  return (
    <Form layout='vertical' onFinish={handleSubmit}>
      <Form.Item
        name='nombres'
        rules={[{ required: true, message: 'El nombre del cliente es obligatorio' }]}
      >
        <Input
          placeholder='Nombre del cliente'
          onChange={(e) => handleChange('nombres', e.target.value)}
          value={formData.nombres}
          autoComplete='off'
        />
      </Form.Item>
      <Form.Item
        name='tipo_doc'
        rules={[{ required: true, message: 'Tipo de documento es obligatorio' }]}
      >
        <Select
          onChange={(value) => handleChange('tipo_doc', value)}
          value={formData.tipo_doc}
          placeholder='Seleccione un tipo de documento'
        >
          <Select.Option value='CC'>CC</Select.Option>
          <Select.Option value='NIT'>NIT</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        name='numero_doc'
        rules={[{ required: true, message: 'El número de documento es obligatorio' }]}
      >
        <Input
          placeholder='Número de documento'
          onChange={(e) => handleChange('numero_doc', e.target.value)}
          value={formData.numero_doc}
          autoComplete='off'
        />
      </Form.Item>
      <Form.Item
        name='correo'
        rules={[{ type: 'email', message: 'El correo electrónico no es válido' }]}
      >
        <Input
          type='email'
          placeholder='Correo Electrónico'
          onChange={(e) => handleChange('correo', e.target.value)}
          value={formData.correo}
        />
      </Form.Item>
      <Form.Item name='celular'>
        <Input
          placeholder='# Celular'
          onChange={(e) => handleChange('celular', e.target.value)}
          value={formData.celular}
          autoComplete='off'
        />
      </Form.Item>
      <Form.Item name='direccion'>
        <Input
          placeholder='Dirección'
          onChange={(e) => handleChange('direccion', e.target.value)}
          value={formData.direccion}
          autoComplete='off'
        />
      </Form.Item>
      <div className='modal-footer d-flex justify-content-between mt-3'>
        <Button danger onClick={handleCancel}>
          Cancelar
        </Button>
        <Button type='primary' htmlType='submit'> 
          Guardar
        </Button>
      </div>
    </Form>
  );
};

export default ClienteForm;
