import { useState } from 'react';
import { API_BASE_URL } from '../config/apiConfig';

export function useUpdateUser() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [isUpdated, setIsUpdated] = useState(false);

    const updateUser = async (userData) => {
        if (!userData.id) {
            setError("El id es requerido para actualizar.");
            console.error("No ID provided for updating.");
            return null;
        }

        setLoading(true);
        setIsUpdated(false);
        try {
            const response = await fetch(`${API_BASE_URL}/usuario`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(userData)
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            
            const contentType = response.headers.get('content-type');
            if (contentType && contentType.includes('application/json')) {
                const data = await response.json();
                console.log("Response Data:", data); 
                
                if (data.success) {
                    setIsUpdated(true);
                    return data;
                } else {
                    throw new Error(data.message || 'Error actualizando el usuario');
                }
            } else {
                throw new Error('Invalid content type: Expected application/json');
            }
        } catch (error) {
            setError(error instanceof Error ? error.message : 'ha ocurrido un error');
            console.error("Update Error:", error);
            return null;
        } finally {
            setLoading(false);
        }
    };

    return { updateUser, loading, error, isUpdated };
}