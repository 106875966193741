import { useState } from 'react';

const useGrabarPrefactura = () => {
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const grabarPrefactura = async (prefacturaData) => {
    setLoading(true);
    setError(null);
    setResponse(null);

    try {
      const response = await fetch('https://api.cuenti.co/jServerj4ErpPro/api/token/grabarFacturaSimple', {
        method: 'POST',
        headers: {
          'X-Auth-Token-empresa': '4411',
          'X-Auth-Token-api': '4411-202002620008ed7rv1194-7bfe-rc8e-b977-a17e04665f26e',
          'Content-Type': 'application/json',
          'X-gtm': 'GMT-0500'
        },
        body: JSON.stringify(prefacturaData)
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      setResponse(data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return { response, loading, error, grabarPrefactura };
};

export default useGrabarPrefactura;